import { FetchResult } from "@apollo/client";

export const batchUpdate = async <T>(
  inputs: T[],
  mutate: (input: T) => Promise<FetchResult<any>>
) => {
  const responses = await Promise.allSettled<{
    input: T;
    result: FetchResult<any>;
  }>(
    inputs.map((input) => {
      return new Promise((resolve, reject) => {
        mutate(input)
          .then((result) => resolve({ input, result }))
          .catch((error) => reject({ input, error }));
      });
    })
  );

  return responses;
};
