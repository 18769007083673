import { Navigate } from "react-router-dom";

import { AccessRequired } from "@m/api/components";

import { ATLAS_FEATURE_FLAGS, PATHS } from "@atlas/constants";
import {
  NewRelicAlertConditionTemplateCreatePage,
  NewRelicAlertConditionTemplateEditPage,
  NewRelicAlertConditionTemplatesPage,
  NewRelicDashboardTemplateCreatePage,
  NewRelicDashboardTemplateEditPage,
  NewRelicDashboardTemplatesPage,
  PersonalAccessTokensPage,
} from "@atlas/features/Settings/pages";

export const settingsRoutes = [
  {
    index: true,
    element: <Navigate to="access-tokens" replace />,
  },
  {
    path: "access-tokens",
    handle: {
      crumb: () => <span>Access Tokens</span>,
      to: PATHS.SETTINGS_ACCESS_TOKENS,
    },
    element: <PersonalAccessTokensPage />,
  },
  {
    path: "alert-conditions",
    handle: {
      crumb: () => <span>Alert Conditions</span>,
      to: PATHS.SETTINGS_ALERT_CONDITION_TEMPLATES,
    },
    children: [
      {
        index: true,
        path: "",
        element: (
          <AccessRequired
            feature={ATLAS_FEATURE_FLAGS.RELEASE_PORTAL_REDESIGN}
            fallback={<Navigate to={PATHS.ROOT} replace />}
          >
            <NewRelicAlertConditionTemplatesPage />
          </AccessRequired>
        ),
      },
      {
        path: "create",
        index: true,
        handle: {
          crumb: () => <span>Create Alert Condition</span>,
          to: PATHS.SETTINGS_ALERT_CONDITION_TEMPLATE_CREATE,
        },
        element: (
          <AccessRequired
            feature={ATLAS_FEATURE_FLAGS.RELEASE_PORTAL_REDESIGN}
            fallback={<Navigate to={PATHS.ROOT} replace />}
          >
            <NewRelicAlertConditionTemplateCreatePage />
          </AccessRequired>
        ),
      },
      {
        path: ":templateId",
        index: true,
        handle: {
          crumb: () => <span>Edit Alert Condtion</span>,
          to: PATHS.SETTINGS_ALERT_CONDITION_TEMPLATE_EDIT,
        },
        element: (
          <AccessRequired
            feature={ATLAS_FEATURE_FLAGS.RELEASE_PORTAL_REDESIGN}
            fallback={<Navigate to={PATHS.ROOT} replace />}
          >
            <NewRelicAlertConditionTemplateEditPage />
          </AccessRequired>
        ),
      },
    ],
  },
  {
    path: "dashboards",
    handle: {
      crumb: () => <span>Dashboards</span>,
      to: PATHS.SETTINGS_DASHBOARD_TEMPLATES,
    },
    children: [
      {
        index: true,
        path: "",
        element: (
          <AccessRequired
            feature={ATLAS_FEATURE_FLAGS.RELEASE_PORTAL_REDESIGN}
            fallback={<Navigate to={PATHS.ROOT} replace />}
          >
            <NewRelicDashboardTemplatesPage />
          </AccessRequired>
        ),
      },
      {
        index: true,
        path: "create",
        handle: {
          crumb: () => <span>Create</span>,
          to: PATHS.SETTINGS_DASHBOARD_TEMPLATE_CREATE,
        },
        element: (
          <AccessRequired
            feature={ATLAS_FEATURE_FLAGS.RELEASE_PORTAL_REDESIGN}
            fallback={<Navigate to={PATHS.ROOT} replace />}
          >
            <NewRelicDashboardTemplateCreatePage />
          </AccessRequired>
        ),
      },
      {
        path: ":templateId",
        index: true,
        handle: {
          crumb: () => <span>Edit Dashboard Template</span>,
          to: PATHS.SETTINGS_DASHBOARD_TEMPLATE_EDIT,
        },
        element: (
          <AccessRequired
            feature={ATLAS_FEATURE_FLAGS.RELEASE_PORTAL_REDESIGN}
            fallback={<Navigate to={PATHS.ROOT} replace />}
          >
            <NewRelicDashboardTemplateEditPage />
          </AccessRequired>
        ),
      },
    ],
  },
];
