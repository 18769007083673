import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { toast } from "@m/ui";
import { recursiveToCamel } from "@m/utils/strings";

import { ACCESS_TOKEN_URL } from "../constants";
import { PersonalAccessToken } from "../types";

export const PERSONAL_ACCESS_TOKENS_QUERY_KEY = "personal-access-tokens";

export const usePersonalAccessTokens = () => {
  const result = useQuery({
    queryKey: [PERSONAL_ACCESS_TOKENS_QUERY_KEY],
    queryFn: fetchPersonalAccessTokens,
  });

  useEffect(() => {
    if (result.isError) toast.error(result.error.message);
  }, [result.isError, result.error]);

  return result;
};

const fetchPersonalAccessTokens = async (): Promise<PersonalAccessToken[]> => {
  const response = await fetch(ACCESS_TOKEN_URL, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("csrf"),
    },
    credentials: "include",
  });

  const body = await response.json();

  if (!response.ok) throw new Error(body.message);

  return body.map((token: any) => recursiveToCamel(token));
};
