import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { ChangeEvent, useState } from "react";
import DatePickerComponent, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Button, Input } from "@m/ui";
import { dt } from "@m/utils";

export const DEFAULT_DATE_FORMAT = "MM/dd/yyyy";

export type DateValueType = [Date | null, Date | null] | Date | null;

export type DatePickerProps = Omit<
  ReactDatePickerProps,
  "onChange" | "onSelect"
> & {
  onChange?: (
    value: DateValueType,
    event?: ChangeEvent<HTMLInputElement>
  ) => void;
  showTodayButton?: boolean;
  onLeftHeaderButtonClick?: () => void;
  onRightHeaderButtonClick?: () => void;
  leftHeaderButtonText?: string;
  rightHeaderButtonText?: string;
  allowRange?: boolean;
  showMonthPicker?: boolean;
  showYearPicker?: boolean;
  dateFormat?: string;
};

const enum CalView {
  Calendar = "Calendar",
  Month = "Month",
  Year = "Year",
}

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DatePicker = ({
  className,
  dateFormat = DEFAULT_DATE_FORMAT,
  disabled,
  placeholderText = DEFAULT_DATE_FORMAT.toLowerCase(),
  name,
  id,
  value,
  minDate,
  maxDate,
  startDate,
  endDate,
  showTodayButton = true,
  onLeftHeaderButtonClick,
  onRightHeaderButtonClick,
  leftHeaderButtonText,
  rightHeaderButtonText,
  showMonthPicker = false,
  showYearPicker = false,
  required = false,
  allowRange = false,
  onChange,
  ...props
}: DatePickerProps) => {
  const selectedDate = value
    ? dt.fromISO(value.toString()).startOf("day").toJSDate()
    : null;

  let initialCalView = CalView.Calendar;
  if (showYearPicker) {
    initialCalView = CalView.Year;
  } else if (showMonthPicker) {
    initialCalView = CalView.Month;
  }

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentCalView, setCurrentCalView] = useState<CalView>(initialCalView);

  const handleDateChange = (
    dateValue: DateValueType,
    event?: ChangeEvent<HTMLInputElement>
  ) => {
    onChange?.(dateValue, event);

    const isSelectionComplete =
      (allowRange && dateValue[0] && dateValue[1]) || // Closes DatePicker calendar when selecting a date range is complete
      (!allowRange && // Closes DatePicker calendar when selecting a single date is complete
        (showMonthPicker ||
          showYearPicker ||
          currentCalView === CalView.Calendar));

    if (isSelectionComplete) {
      setIsOpen(false);
      return;
    }

    // Sets the next picker view when selection is not completed
    setCurrentCalView(
      currentCalView === CalView.Year ? CalView.Month : CalView.Calendar
    );
  };

  const handleInputClick = () => {
    if (!showMonthPicker && !showYearPicker) {
      setCurrentCalView(CalView.Calendar);
    }
    setIsOpen(true);
  };

  const handleSelect = () => {
    if (!showYearPicker && !showMonthPicker) {
      currentCalView === CalView.Year
        ? setCurrentCalView(CalView.Month)
        : setCurrentCalView(CalView.Calendar);
    }
  };

  const handleLeftButtonClick = () => {
    onLeftHeaderButtonClick();
    setIsOpen(false);
  };

  const handleRightButtonClick = () => {
    onRightHeaderButtonClick();
    setIsOpen(false);
  };

  const toggleCalView = () =>
    setCurrentCalView(
      currentCalView === CalView.Calendar ? CalView.Month : CalView.Year
    );

  return (
    <div className={className}>
      <DatePickerComponent
        name={name}
        id={id}
        required={required}
        portalId="date-picker-portal"
        disabled={disabled}
        startDate={startDate}
        endDate={endDate}
        maxDate={maxDate}
        minDate={minDate}
        dateFormat={dateFormat}
        selected={selectedDate}
        placeholderText={placeholderText}
        shouldCloseOnSelect
        fixedHeight
        enableTabLoop={false}
        selectsRange={allowRange}
        isClearable
        clearButtonTitle="Clear"
        wrapperClassName="w-full"
        calendarClassName={
          "form-date-picker border-gray-400 pb-3 p-1 w-[340px]"
        }
        weekDayClassName={() => "mx-1 text-gray-500"}
        monthClassName={() => "px-2 py-1 font-sans font-semibold text-action"}
        dayClassName={() => "mx-1 rounded-full hover:rounded-full text-default"}
        customInput={<Input leftIcon={CalendarIcon} {...props} />}
        renderCustomHeader={(props) => (
          <DatePickerHeader
            currentCalView={currentCalView}
            toggleCalView={toggleCalView}
            handleDateChange={handleDateChange}
            allowRange={allowRange}
            onLeftHeaderButtonClick={handleLeftButtonClick}
            onRightHeaderButtonClick={handleRightButtonClick}
            leftHeaderButtonText={leftHeaderButtonText}
            rightHeaderButtonText={rightHeaderButtonText}
            showTodayButton={showTodayButton}
            {...props}
          />
        )}
        showMonthYearPicker={
          showMonthPicker || currentCalView === CalView.Month
        }
        showYearPicker={showYearPicker || currentCalView === CalView.Year}
        openToDate={selectedDate}
        open={isOpen}
        showFullMonthYearPicker
        onChange={handleDateChange}
        onClickOutside={() => setIsOpen(false)}
        onInputClick={handleInputClick}
        onSelect={handleSelect}
        {...props}
      />
    </div>
  );
};

const DatePickerHeader = ({
  currentCalView,
  date,
  changeYear,
  changeMonth,
  toggleCalView,
  handleDateChange,
  allowRange,
  showTodayButton,
  onLeftHeaderButtonClick,
  onRightHeaderButtonClick,
  leftHeaderButtonText,
  rightHeaderButtonText,
}: {
  currentCalView: CalView;
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  toggleCalView: () => void;
  handleDateChange: (
    dateValue: DateValueType,
    event: ChangeEvent<HTMLInputElement> | undefined
  ) => void;
  allowRange: boolean;
  showTodayButton: boolean;
  onLeftHeaderButtonClick?: () => void;
  onRightHeaderButtonClick?: () => void;
  leftHeaderButtonText?: string;
  rightHeaderButtonText?: string;
}) => {
  const handleCalPageChange = (amount: number) => {
    if (currentCalView === CalView.Month) {
      changeYear(date.getFullYear() + amount);
    } else {
      changeMonth(date.getMonth() + amount);
    }
  };

  const handleTodayClick = (event: ChangeEvent<HTMLInputElement>) => {
    changeYear(date.getFullYear());
    changeMonth(date.getMonth());
    const startOfToday = dt.now().startOf("day").toJSDate();

    const dateValue: DateValueType = allowRange
      ? [startOfToday, null]
      : startOfToday;

    handleDateChange(dateValue, event);
  };

  return (
    <>
      <div className="custom-header m-2 mb-1.5 flex items-center justify-between">
        <Button
          aria-label={"Previous month"}
          onClick={() => handleCalPageChange(-1)}
          className="border-0"
          fill="subdued"
        >
          <ChevronLeftIcon className="h-3 w-2" />
        </Button>

        <Button
          data-testid="date-picker-view-toggle"
          fill="none"
          onClick={toggleCalView}
        >
          <span className="font-sans font-semibold text-default hover:text-action">
            {(currentCalView === CalView.Calendar ||
              currentCalView === CalView.Month) &&
              MONTHS[date.getMonth()]}{" "}
            {date.getFullYear()}
          </span>
        </Button>

        <Button
          aria-label={"Next month"}
          onClick={() => handleCalPageChange(1)}
          className="border-0"
          fill="subdued"
        >
          <ChevronRightIcon className="h-3 w-2" />
        </Button>
      </div>

      <div className="flex justify-between gap-1 px-2">
        {/* TODO(jamesmoody): Add support for allowing a list of header buttons to be passed as a prop to DatePicker */}
        {[
          {
            text: leftHeaderButtonText,
            onClick: onLeftHeaderButtonClick,
            key: "date-picker-left-button",
          },
          {
            text: "Today",
            onClick: handleTodayClick,
            key: "date-picker-today-button",
            show: showTodayButton,
          },
          {
            text: rightHeaderButtonText,
            onClick: onRightHeaderButtonClick,
            key: "date-picker-right-button",
          },
        ].map(({ text, onClick, key, show = true }) =>
          text && onClick && show ? (
            <div key={key} className="w-1/3">
              <Button
                data-testid={key}
                onClick={onClick}
                className="mb-1.5 w-full border-0 p-3"
                fill="subdued"
              >
                <span className="whitespace-nowrap text-2xs">{text}</span>
              </Button>
            </div>
          ) : (
            <div key={key} className="w-1/3"></div>
          )
        )}
      </div>
    </>
  );
};
