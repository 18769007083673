import { Navigate, useParams } from "react-router-dom";

import { useCustomerOverview } from "../api";
import {
  CustomerDetailsCard,
  CustomerDetailsHeader,
  CustomerEntitlements,
} from "../components";

export const CustomerDetailsPage = () => {
  const { companySlug = "" } = useParams<{ companySlug: string }>();

  const {
    data: {
      company,
      salesforce,
      entitlements: { bundles, products, addons, discounts },
    },
    loading,
  } = useCustomerOverview(companySlug);

  if (!loading && !company) {
    return <Navigate to="/customers" />;
  }

  return (
    <div className="space-y-4">
      <CustomerDetailsHeader
        databaseId={company?.databaseId}
        loading={loading}
        mcn={company?.mcn}
        name={company?.name}
        status={company?.status}
        timestampCreated={company?.timestampCreated}
      />

      <div id="Cards" className="flex gap-3">
        <CustomerDetailsCard
          title="Company Details"
          fields={[
            ["Website", salesforce?.website],
            ["Phone Number", salesforce?.phoneNumber],
            ["Size", salesforce?.companySize?.toString()],
            ["Industry", salesforce?.industry],
          ]}
          loading={loading}
        />
        <CustomerDetailsCard
          title="AWS Information"
          fields={[
            ["Representative", salesforce?.awsRep],
            ["Segment", salesforce?.awsSegment],
            ["Phase", salesforce?.awsPhases],
            ["District", salesforce?.awsDistrict],
          ]}
          loading={loading}
        />
        <CustomerDetailsCard
          title="Connectwise Information"
          fields={[
            ["ID", company?.connectwisePk],
            ["Identifier", company?.identifier],
            ["Tags", company?.types],
          ]}
          loading={loading}
        />
      </div>

      <CustomerEntitlements
        title="Bundles"
        entitlements={bundles}
        loading={loading}
      />

      <CustomerEntitlements
        title="Products"
        entitlements={products}
        loading={loading}
      />

      <CustomerEntitlements
        title="Add-Ons / Discounts"
        entitlements={[...addons, ...discounts]}
        loading={loading}
      />
    </div>
  );
};
