import {
  AddMeteredChargeModal,
  MeteredChargesTable,
  MeteredChargesTableFilter,
  StopMeteringModal,
} from ".";
import { ArrowUpTrayIcon, BoltIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  DateParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  MarketplaceMeteringMethod,
  MarketplaceMeteringStatus,
} from "@m/api/v4/types";
import { Button, Card, Select, useModalState } from "@m/ui";
import { dt } from "@m/utils";

import {
  SubscriptionDetails,
  useExportMeteredCharges,
  useMeteredCharges,
} from "../api";

import { MeteredChargesTableActions } from "./MeteredChargesTableActions";

interface MeteredChargesSectionProps {
  companyId: number;
  loading: boolean;
  subscriptionDetails: SubscriptionDetails;
}

export const MeteredChargesSection = ({
  companyId,
  loading,
  subscriptionDetails,
}: MeteredChargesSectionProps) => {
  const {
    marketplaceProductCode,
    onboardingCompleteTimestamp,
    catalogItem,
    meteringStatus,
    signupToken: marketplaceSignupToken,
  } = subscriptionDetails || {};

  const dimensions = catalogItem?.dimensions.map(
    ({ id, atlasName, dimensionTypeId, meteringMethod }) => ({
      id,
      displayName: atlasName,
      dimensionTypeId,
      meteringMethod,
    })
  );

  const manualDimensions = useMemo(
    () =>
      (dimensions || []).filter(
        (dimension) =>
          dimension.meteringMethod === MarketplaceMeteringMethod.Manual
      ),
    [dimensions]
  );

  const [queryParams, setQueryParams] = useQueryParams({
    productCode: withDefault(StringParam, marketplaceProductCode),
    dimensionCode: withDefault(StringParam, null),
    startDate: withDefault(DateParam, dt.now().startOf("month").toJSDate()),
    endDate: withDefault(DateParam, dt.now().endOf("month").toJSDate()),
  });

  const addMeteredChargeModal = useModalState();
  const stopMeteringModal = useModalState();

  const canAddMeteredCharge = manualDimensions.length > 0;
  const canStopMetering = meteringStatus === MarketplaceMeteringStatus.Active;

  const { mutateAsync: startExport, isPending: isExportLoading } =
    useExportMeteredCharges(companyId, queryParams);

  const {
    data: { meteredCharges, dimensionTotals },
    loading: chargesLoading,
    pagination,
  } = useMeteredCharges(companyId, queryParams);

  const isLoading = loading || chargesLoading;

  const monthOptions = useMemo(() => {
    const currentDate = dt.now().startOf("month");
    let date = dt.fromISO(onboardingCompleteTimestamp).startOf("month");

    const options = [];

    while (date <= currentDate) {
      options.push({
        label:
          date.hasSame(currentDate, "month") &&
          date.hasSame(currentDate, "year")
            ? "Current (unbilled)"
            : date.toFormat("MMMM yyyy"),
        startDate: date.startOf("month").toJSDate(),
        endDate: date.endOf("month").toJSDate(),
      });

      date = date.plus({ months: 1 });
    }

    return options.reverse();
  }, [onboardingCompleteTimestamp]);

  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(
    monthOptions[0]
  );

  // If a month filter has already been applied via query params, we'll set the selected month based on the values
  useEffect(() => {
    const billingPeriod =
      monthOptions.find(
        ({ startDate }) =>
          startDate.toISOString() === queryParams.startDate?.toISOString()
      ) ?? monthOptions[0];

    setSelectedBillingPeriod(billingPeriod);
  }, [monthOptions, queryParams]);

  const handleBillingPeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedMonth = monthOptions.find(
      (option) => option.label === event.target.value
    );
    const { startDate, endDate } = selectedMonth;

    setSelectedBillingPeriod(selectedMonth || monthOptions[0]);
    setQueryParams({ ...queryParams, startDate, endDate });
  };

  const handleResetFilters = () => {
    setSelectedBillingPeriod(monthOptions[0]);
    setQueryParams({
      startDate: null,
      endDate: null,
      productCode: null,
      dimensionCode: null,
    });
  };

  if (!isLoading && !marketplaceSignupToken) return null;

  return (
    <div data-testid="metered-charges-section" className="flex flex-col gap-3">
      <div className="whitespace-nowrap font-semibold">
        Marketplace Charges Summary
      </div>
      <div className="flex w-1/3">
        <span className="mx-1 whitespace-nowrap font-semibold text-subdued">
          Selected month
        </span>
        <Select
          className="mx-1 w-[200px] p-0"
          id="metered-charges-month-selector"
          data-testid="metered-charges-month-selector"
          name="billed-month"
          onChange={handleBillingPeriodChange}
          options={monthOptions.map(({ label }) => (
            <option key={label} value={label}>
              {label}
            </option>
          ))}
          value={selectedBillingPeriod?.label}
        />
      </div>
      <div className="grid h-fit grid-cols-2 gap-2">
        {dimensionTotals.map(
          ({ dimensionCode, dimensionDescription, dimensionTotal }) =>
            dimensionTotal !== null && (
              <Card key={dimensionCode} className="flex h-full gap-1 p-1.5">
                <BoltIcon className="h-2.5 w-2.5" />
                <span className="font-semibold">
                  {dimensionDescription}:
                </span>{" "}
                {dimensionTotal?.toLocaleString()} Units Metered
              </Card>
            )
        )}
      </div>
      <div className="flex h-fit">
        <MeteredChargesTableFilter
          query={queryParams}
          dimensions={dimensions || []}
          handleQueryChange={setQueryParams}
          handleResetFilters={handleResetFilters}
          loading={isLoading}
        />
        <Button
          data-testid="export-metered-charges-button"
          kind="primary"
          fill="solid"
          size="small"
          className="mx-2 h-fit"
          leftIcon={ArrowUpTrayIcon}
          loading={isExportLoading}
          onClick={startExport}
        >
          Export
        </Button>
        <MeteredChargesTableActions
          canAddMeteredCharge={canAddMeteredCharge}
          canStopMetering={canStopMetering}
          disabled={loading}
          onAddMeteredChargeClick={addMeteredChargeModal.open}
          onStopMeteringClick={stopMeteringModal.open}
        />
      </div>
      <MeteredChargesTable
        meteredCharges={meteredCharges}
        dimensions={dimensions}
        isLoading={isLoading}
        {...pagination}
      />
      <AddMeteredChargeModal
        isOpen={addMeteredChargeModal.isOpen}
        onClose={addMeteredChargeModal.close}
        subscriptions={[
          {
            marketplaceProductCode: subscriptionDetails?.marketplaceProductCode,
            customerIdentifier: subscriptionDetails?.customerIdentifier,
            displayName: subscriptionDetails?.catalogItem?.displayName,
          },
        ]}
      />
      <StopMeteringModal
        customerIdentifier={subscriptionDetails?.customerIdentifier ?? ""}
        isOpen={stopMeteringModal.isOpen}
        onClose={stopMeteringModal.close}
      />
    </div>
  );
};
