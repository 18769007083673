import { useState } from "react";

import { Button, Buttons, DatePicker, Field, Fields, Input } from "@m/ui";

import { useCreatePersonalAccessToken } from "../api";

interface PersonalAccessTokenFormProps {
  onTokenCreated: (token: string, expiresAt: string) => void;
}

export const PersonalAccessTokenForm = ({
  onTokenCreated,
}: PersonalAccessTokenFormProps) => {
  const { mutate: createPersonalAccessToken, isPending } =
    useCreatePersonalAccessToken(onTokenCreated);

  const [name, setName] = useState<string>("");
  const [expirationDate, setExpirationDate] = useState<string>();

  const handleExpirationDateChange = (date: Date | null) => {
    setExpirationDate(date?.toISOString());
  };

  const handleClearForm = () => {
    setName("");
    setExpirationDate(undefined);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    createPersonalAccessToken(
      { name, expirationDate },
      { onSuccess: handleClearForm }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-3">
      <Fields>
        <Field label="Name" name="name" htmlFor="name">
          <Input
            id="name"
            placeholder="Pick a name for the application or the purpose of the token"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Field>
        <Field
          label="Expiration Date"
          name="expirationDate"
          flag="optional"
          htmlFor="expiration-date"
        >
          <DatePicker
            id="expiration-date"
            value={expirationDate}
            onChange={handleExpirationDateChange}
          />
        </Field>
      </Fields>

      <Buttons align="right">
        <Button kind="primary" type="submit" loading={isPending}>
          Generate token
        </Button>
      </Buttons>
    </form>
  );
};
