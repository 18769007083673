import { useQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Params, generatePath, useNavigate, useParams } from "react-router-dom";

import { Button, Suspensed, toast, useModalState } from "@m/ui";

import { PageTitle } from "@atlas/components";
import { PATHS } from "@atlas/constants";

import {
  GET_CUSTOMER_SUBSCRIPTION_DETAILS,
  useCustomerOverview,
  useCustomerSubscriptionDetails,
} from "../api";
import {
  MeteredChargesSection,
  ProductInformationModal,
  SubscriptionDetailsItems,
} from "../components";

interface CustomerSubscriptionDetailsPageParams extends Params {
  companySlug: string;
  marketplaceProductCode: string;
}

export const CustomerSubscriptionDetailsPage = () => {
  const navigate = useNavigate();

  const { companySlug = "", marketplaceProductCode = "" } =
    useParams<CustomerSubscriptionDetailsPageParams>();

  const {
    data: { subscriptionDetails },
    loading: loadingSubscriptionDetails,
  } = useCustomerSubscriptionDetails(companySlug, marketplaceProductCode);

  const {
    data: { company },
    loading: loadingCompany,
  } = useCustomerOverview(companySlug);

  const { catalogItem } = subscriptionDetails ?? {};
  const productInfoModal = useModalState();

  if (!companySlug) navigate(generatePath(PATHS.ROOT));
  if (!catalogItem && !loadingSubscriptionDetails) {
    toast.error("Could not find customer subscription.");
    navigate(generatePath(PATHS.CUSTOMER_SUBSCRIPTIONS, { companySlug }));
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <Suspensed
          loading={loadingSubscriptionDetails}
          height="24px"
          width="280px"
        >
          <PageTitle title={catalogItem?.displayName} />
          <Button
            fill="none"
            leftIcon={InformationCircleIcon}
            onClick={productInfoModal.open}
          />
        </Suspensed>
      </div>
      <div className="flex flex-col gap-5">
        <SubscriptionDetailsItems
          loading={loadingSubscriptionDetails}
          subscriptionDetails={subscriptionDetails}
        />
        {!loadingCompany && (
          <MeteredChargesSection
            companyId={company?.databaseId}
            subscriptionDetails={subscriptionDetails}
            loading={loadingSubscriptionDetails}
          />
        )}
      </div>
      <ProductInformationModal
        catalogItem={subscriptionDetails?.catalogItem ?? null}
        isOpen={productInfoModal.isOpen}
        onClose={productInfoModal.close}
      />
    </div>
  );
};

CustomerSubscriptionDetailsPage.Crumb = function Crumb() {
  const { companySlug, marketplaceProductCode } =
    useParams<CustomerSubscriptionDetailsPageParams>();

  const { data, loading } = useQuery(GET_CUSTOMER_SUBSCRIPTION_DETAILS, {
    variables: { companySlug, where: { marketplaceProductCode } },
    fetchPolicy: "cache-first",
  });

  const displayName =
    data?.marketplaceRegistrations?.edges?.[0]?.node?.catalogItem
      ?.displayName ?? "Product";

  return (
    <Suspensed loading={loading} width="200px" height="20px">
      {displayName}
    </Suspensed>
  );
};
