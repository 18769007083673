import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";

export const DELETE_NEW_RELIC_DASHBOARD = gql(/* GraphQL */ `
  mutation DeleteNewRelicDashboard($id: ID!) {
    deleteNewRelicDashboard(id: $id) {
      ok
      message
    }
  }
`);

export const useDeleteNewRelicDashboard = () => {
  const [mutate, result] = useMutation(DELETE_NEW_RELIC_DASHBOARD);

  const deleteNewRelicDashboard = (dashboardId: string) => {
    return mutate({ variables: { id: dashboardId } });
  };

  return [deleteNewRelicDashboard, result] as const;
};
