import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import { GetNewRelicAccountDetailsQuery } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const GET_NEW_RELIC_ACCOUNT_DETAILS = gql(/* GraphQL */ `
  query GetNewRelicAccountDetails($accountId: ID!) {
    newRelicAccount(id: $accountId) {
      id
      accountId
      lastSynced
      name
      syncState
      dashboards {
        edges {
          node {
            id
            template {
              id
            }
          }
        }
      }
      conditions {
        edges {
          node {
            id
            template {
              id
            }
          }
        }
      }
      policies {
        edges {
          node {
            id
            template {
              id
            }
          }
        }
      }
    }
  }
`);

export const useNewRelicAccountDetails = (accountId: string) => {
  const { data, ...result } = useQuery(GET_NEW_RELIC_ACCOUNT_DETAILS, {
    variables: {
      accountId,
    },
    onError: () => toast.error("Unable to fetch New Relic account details."),
  });

  const account = data?.newRelicAccount;
  const dashboards = account?.dashboards.edges.map((edge) => edge.node) ?? [];
  const conditions = account?.conditions.edges.map((edge) => edge.node) ?? [];
  const policies = account?.policies.edges.map((edge) => edge.node) ?? [];

  return { data: { account, dashboards, conditions, policies }, ...result };
};

export type NewRelicAccountDetails = Omit<
  GetNewRelicAccountDetailsQuery["newRelicAccount"],
  "dashboards" | "conditions" | "policies"
>;

export type NewRelicDashboard =
  GetNewRelicAccountDetailsQuery["newRelicAccount"]["dashboards"]["edges"][number]["node"];

export type NewRelicCondition =
  GetNewRelicAccountDetailsQuery["newRelicAccount"]["conditions"]["edges"][number]["node"];

export type NewRelicPolicy =
  GetNewRelicAccountDetailsQuery["newRelicAccount"]["policies"]["edges"][number]["node"];
