import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useMemo, useRef } from "react";
import Select, {
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  InputProps,
  MenuProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  SelectInstance,
  StylesConfig,
  components,
} from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";

import tailwindConfig from "@m/tailwind-config";
import { Checkbox } from "@m/ui";

const placeholderColor = tailwindConfig.theme.extend.colors?.form?.placeholder;

export type OptionType = {
  value: string;
  label: string;
  name?: string;
  subLabel?: string;
  __isNew__?: boolean; // This comes from react-select to note a custom option that can be created by a user
  isUserCreated?: boolean; // Used on our end too keep track of user created options after they have been selected
};

interface DynamicSelectProps
  extends CreatableProps<OptionType, true, GroupBase<OptionType>> {
  allowUserCreatedOptions?: boolean;
  initialOptions: OptionType[];
  onSelectedOptionsChange?: (
    selectedOptions: OptionType | OptionType[]
  ) => void;
}

export const DynamicSelect = ({
  id,
  name,
  placeholder,
  isMulti,
  initialOptions,
  allowUserCreatedOptions,
  onSelectedOptionsChange,
  ...props
}: DynamicSelectProps) => {
  const selectRef = useRef<SelectInstance<OptionType, false> | null>(null);

  const selectStyle = useMemo<StylesConfig<OptionType, true>>(
    () => ({
      // Overwrite a few default styles from react-select
      multiValue: (style) => ({ ...style, borderRadius: 15 }),
      control: (style) => ({
        // Removes the default focus highlighting so we can add our own
        ...style,
        border: 0,
        boxShadow: "none",
      }),
      placeholder: (style) => ({ ...style, color: placeholderColor }),
    }),
    []
  );

  const SelectComponent = allowUserCreatedOptions ? CreatableSelect : Select;

  return (
    <SelectComponent
      ref={selectRef}
      id={id}
      name={name}
      {...(onSelectedOptionsChange && { onChange: onSelectedOptionsChange })}
      options={initialOptions}
      placeholder={placeholder}
      isMulti={isMulti}
      isSearchable={isMulti}
      isClearable={isMulti}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      styles={selectStyle}
      menuPortalTarget={document.body}
      components={{
        Input,
        Option,
        Control,
        MultiValueContainer,
        Menu,
        MultiValueLabel,
        MultiValueRemove,
        DropdownIndicator,
        IndicatorSeparator: null,
      }}
      {...props}
    />
  );
};

const Control = (props: ControlProps<OptionType>) => {
  const { innerRef, innerProps, isDisabled } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={clsx(
        "form-control-container form-control__focus-within form-placeholder w-full",
        {
          "opacity-60": isDisabled,
        }
      )}
    >
      <components.Control {...props} />
    </div>
  );
};

const MultiValueContainer = (props: MultiValueGenericProps<OptionType>) => {
  const { innerProps, data } = props;
  return (
    <div
      {...innerProps}
      data-testid={`select-option-pill-${data.value}`}
      className={"w-fit items-center rounded-full text-left"}
    >
      <components.MultiValueContainer {...props} />
    </div>
  );
};

const MultiValueLabel = (props: MultiValueGenericProps<OptionType>) => {
  const { data, innerProps } = props;
  return (
    <div {...innerProps} className="pl-1 text-xs font-semibold">
      {data.label}
    </div>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
  const { selectProps } = props;
  return (
    <button
      type="button"
      aria-label="Toggle dropdown"
      data-testid={`${selectProps.id}-select-dropdown-button`}
      className="flex items-center"
    >
      <ChevronDownIcon className="h-2 w-2" />
    </button>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<OptionType>) => {
  const { innerProps, data } = props;
  return (
    <div
      {...innerProps}
      className="mx-0.5 hover:bg-transparent"
      data-testid={`select-clear-value-${data.value}`}
    >
      <XMarkIcon className="h-2 w-2 opacity-50 hover:opacity-80" />
    </div>
  );
};

const Option = (props: OptionProps<OptionType>) => {
  const { isSelected, innerRef, innerProps, data, isMulti } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="flex cursor-pointer items-center rounded-lg p-1 hover:bg-gray-100"
    >
      {isMulti && !data.__isNew__ && (
        // __isNew__ comes from react-select and is true when the 'create option' prompt is shown to the user
        <Checkbox checked={isSelected} className="mx-1" readOnly />
      )}
      <div className="font-semibold" data-testid={`option-${data.value}`}>
        {data.label}
        {data.subLabel && (
          <p className="text-xs font-semibold text-subdued">{data.subLabel}</p>
        )}
      </div>
    </div>
  );
};

const Menu = ({ children, ...props }: MenuProps<OptionType>) => {
  return (
    <components.Menu {...props}>
      <div className="px-1 py-0.5">{children}</div>
    </components.Menu>
  );
};

const Input = (props: InputProps<OptionType>) => {
  const { selectProps } = props;
  return (
    <components.Input
      data-testid={`${selectProps.id}select-input`}
      {...props}
    />
  );
};
