import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { CreateNewRelicDomainMonitorInput } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const CREATE_NEW_RELIC_ACCOUNT_DOMAIN_MONITOR = gql(/* GraphQL */ `
  mutation CreateDomainMonitor($input: CreateNewRelicDomainMonitorInput!) {
    createNewRelicDomainMonitor(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateNewRelicAccountDomainMonitor = () => {
  const onError = () => toast.error("Failed to create domain monitor.");

  const [mutate, { loading }] = useMutation(
    CREATE_NEW_RELIC_ACCOUNT_DOMAIN_MONITOR,
    {
      onError,
      refetchQueries: ["GetNewRelicAccountDomainMonitors"],
    }
  );

  const createDomainMonitor = (input: CreateNewRelicDomainMonitorInput) => {
    return mutate({ variables: { input } });
  };

  return {
    createDomainMonitor,
    loading,
  };
};
