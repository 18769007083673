import { ReactNode, useMemo } from "react";

import { useAuth0Logout, useUserSession } from "@m/login/hooks";
import { UserSession } from "@m/types";

import { AuthContext } from "../contexts";

interface AuthProviderProps {
  children: ReactNode;
  defaultUser?: UserSession;
}

export const AuthProvider = ({
  defaultUser = null,
  children,
}: AuthProviderProps) => {
  const { user, updateUserSession } = useUserSession(defaultUser);
  const logout = useAuth0Logout();

  const isAuthenticated = !!user;

  const authContextValue = useMemo(
    () => ({ isAuthenticated, user, updateUserSession, logout }),
    [isAuthenticated, user, updateUserSession, logout]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
