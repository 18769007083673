import * as React from "react";

export interface CheckboxProps extends React.ComponentPropsWithoutRef<"input"> {
  label?: React.ReactNode;
  description?: React.ReactNode;
}

const Checkbox = React.forwardRef<React.ElementRef<"input">, CheckboxProps>(
  ({ className, label, description, ...props }, ref) => (
    <div className="flex items-center gap-1">
      <input type="checkbox" ref={ref} className={className} {...props} />
      {(label || description) && (
        <span className="flex flex-col items-start">
          {label && (
            <label className="text-sm font-semibold" htmlFor={props.id}>
              {label}
            </label>
          )}
          {description && (
            <div className="text-xs text-subdued">{description}</div>
          )}
        </span>
      )}
    </div>
  )
);
Checkbox.displayName = "Checkbox";

export { Checkbox };
