import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import { GetNewRelicDomainMonitorDetailsQuery } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const GET_NEW_RELIC_DOMAIN_MONITOR_DETAILS = gql(/* GraphQL */ `
  query GetNewRelicDomainMonitorDetails($id: ID!) {
    newRelicDomainMonitor(id: $id) {
      activeIncident {
        title
      }
      databaseId
      domain
      enabled
      expireThreshold
      expires
      id
      lastSynced
      name
      registrarId
      registrarName
      statusCodeAlertPreference
    }
  }
`);

export const useNewRelicDomainMonitorDetails = (domainMonitorId: string) => {
  const { data, ...result } = useQuery(GET_NEW_RELIC_DOMAIN_MONITOR_DETAILS, {
    variables: { id: domainMonitorId },
    onError: () => toast.error("Unable to fetch domain monitor details."),
  });

  const domainMonitor = data?.newRelicDomainMonitor;

  return { data: { domainMonitor }, ...result };
};

export type NewRelicDomainMonitorDetails =
  GetNewRelicDomainMonitorDetailsQuery["newRelicDomainMonitor"];
