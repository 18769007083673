import { useState } from "react";

import { Button, Buttons } from "@m/ui";

import { PageTitle } from "@atlas/components/PageTitle";

import {
  PersonalAccessToken,
  PersonalAccessTokenForm,
  PersonalAccessTokensTable,
} from "../components";

export const PersonalAccessTokensPage = () => {
  const [tokenData, setTokenData] = useState<TokenData | null>(null);

  const handleTokenCreated = (token: string, expiresAt: string) => {
    setTokenData({ token, expiresAt });
  };

  const resetTokenData = () => setTokenData(null);

  return (
    <main className="flex flex-col gap-3 px-1">
      <PageTitle
        title="Access Tokens"
        description="Manage your personal access tokens."
      />

      {tokenData ? (
        <>
          <PersonalAccessToken
            token={tokenData.token}
            expiresAt={tokenData.expiresAt}
          />
          <Buttons align="right">
            <Button kind="primary" fill="none" onClick={resetTokenData}>
              Go Back
            </Button>
          </Buttons>
        </>
      ) : (
        <>
          <PersonalAccessTokenForm onTokenCreated={handleTokenCreated} />
          <PersonalAccessTokensTable />
        </>
      )}
    </main>
  );
};

interface TokenData {
  token: string;
  expiresAt: string;
}
