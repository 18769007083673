import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ApmRequirement } from "@m/api/v4/types";
import {
  Button,
  Buttons,
  Checkbox,
  Field,
  Fields,
  Input,
  Link,
  Modal,
  Select,
  Textarea,
  useModalState,
} from "@m/ui";
import { fromSnakeCaseToProperCase } from "@m/utils";

import { PageTitle } from "@atlas/components";

import {
  useDeleteNewRelicDashboardTemplate,
  useNewRelicDashboardTemplate,
  useUpdateNewRelicDashboardTemplate,
} from "../api";

export const NewRelicDashboardTemplateEditPage = () => {
  const navigate = useNavigate();
  const { templateId } = useParams<{ templateId: string }>();

  const {
    data: { dashboardTemplate },
    loading: loadingTemplate,
  } = useNewRelicDashboardTemplate(templateId);

  const [updateDashboardTemplate, { loading: loadingUpdate }] =
    useUpdateNewRelicDashboardTemplate();

  const [deleteDashboardTemplate, { loading: loadingDelete }] =
    useDeleteNewRelicDashboardTemplate();

  const [name, setName] = useState<string>(dashboardTemplate?.name ?? "");
  const [isDefault, setIsDefault] = useState<boolean>(
    dashboardTemplate?.default ?? false
  );
  const [apmRequirement, setApmRequirement] = useState<string>(
    dashboardTemplate?.apmRequirement ?? Object.values(ApmRequirement)[0]
  );
  const [jsonData, setJsonData] = useState<string>(
    dashboardTemplate?.data ?? "{}"
  );
  const [dashboardDataError, setDashboardDataError] = useState<string>("");

  const loadingQueries = loadingTemplate;
  const loadingMutations = loadingUpdate || loadingDelete;

  const deleteModal = useModalState();
  useEffect(() => {
    if (dashboardTemplate && !loadingTemplate) {
      setName(dashboardTemplate.name);
      setIsDefault(dashboardTemplate.default);
      setApmRequirement(dashboardTemplate.apmRequirement);
      setJsonData(dashboardTemplate.data ?? "{}");
    }
  }, [dashboardTemplate, loadingTemplate]);

  const returnToDashboards = () => navigate("../");

  const validateDataJson = (data: string) => {
    try {
      const parsedData = JSON.parse(data);
      if (typeof parsedData !== "object" || parsedData === null) {
        throw new Error();
      }
    } catch (error) {
      throw new Error("Dashboard data must be a valid JSON object");
    }

    setDashboardDataError("");
  };

  const formatJsonData = () => {
    try {
      validateDataJson(jsonData);
      setJsonData(JSON.stringify(JSON.parse(jsonData), null, 2));
    } catch (error) {
      return jsonData;
    }
  };

  const handleClickCancel = () => returnToDashboards();

  const handleNameChange = (e: FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleIsDefaultCheckboxChange = (e: FormEvent<HTMLInputElement>) => {
    setIsDefault(e.currentTarget.checked);
  };

  const handleDataJsonChange = (e: FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setJsonData(value);
  };

  const handleBlurDashboardData = (e: FormEvent<HTMLTextAreaElement>) => {
    try {
      validateDataJson(e.currentTarget.value);
    } catch (error) {
      setDashboardDataError(error.message);
    }
  };

  const handleClickDelete = async () => {
    deleteDashboardTemplate(templateId).then((onCompleted) => {
      if (onCompleted) {
        deleteModal.close();
        returnToDashboards();
      }
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      validateDataJson(jsonData);
    } catch (error) {
      setDashboardDataError(error.message);
      return;
    }

    updateDashboardTemplate({
      id: templateId,
      name,
      default: isDefault,
      apmRequirement: apmRequirement as ApmRequirement,
      data: jsonData,
    }).then(returnToDashboards);
  };

  const loading = loadingTemplate || loadingUpdate || loadingDelete;

  return (
    <form className="mb-3 flex flex-col gap-3 px-1" onSubmit={handleSubmit}>
      <PageTitle title="Edit Dashboard Template" />
      <Fields>
        <Field
          className="max-w-xl"
          description="This name exists to identify the dashboard in Atlas."
          htmlFor="name"
          label="Name"
        >
          <Input
            id="name"
            name="nameInput"
            required
            value={name}
            onChange={handleNameChange}
            disabled={loading}
          />
        </Field>

        <Checkbox
          description="This will cause the dashboard to be automatically selected when setting up new accounts."
          id="default"
          label="Default dashboard"
          name="defaultCheckbox"
          checked={isDefault}
          onChange={handleIsDefaultCheckboxChange}
          disabled={loading}
        />

        <Field
          className="max-w-xl"
          description="Determines if applications must be present within the account in order for the Dashboard to be created."
          htmlFor="apm-requirement"
          label="APM Requirements"
        >
          <Select
            id="apm-requirement"
            name="apmRequirementSelect"
            value={apmRequirement}
            onChange={(e) => setApmRequirement(e.target.value)}
            disabled={loading}
            options={Object.values(ApmRequirement).map((value) => (
              <option key={value} value={value}>
                {fromSnakeCaseToProperCase(value)}
              </option>
            ))}
          />
        </Field>

        <div className="text-sm font-medium">
          <div className="mb-1 text-subdued">
            The following template variables are available for all string
            values:
          </div>

          <ul className="list-disc pl-3">
            <li>
              <span className="font-semibold">{`\${accountId}`}</span> - New
              Relic account ID
            </li>
            <li>
              <span className="font-semibold">{`\${customerName}`}</span> - The
              customer's name
            </li>
            <li>
              <span className="font-semibold">{`\${appIds}`}</span> - List of
              Application IDs from the account
            </li>
          </ul>
        </div>

        <div className="text-sm">
          <div>
            <strong className="text-action">account_id</strong> fields with a
            value of {`"\${accountId}"`} will automatically be converted to an
            integer.
          </div>
          <div>
            <strong className="text-action">entity_ids</strong> fields with a
            value of {`"\${appIds}"`} will automatically be converted to a list
            of integers.
          </div>
        </div>

        <Field
          error={dashboardDataError}
          htmlFor="data"
          label="Data"
          description={
            <>
              This is the JSON representation of the New Relic dashboard. Please
              visit{" "}
              <Link
                href="https://docs.newrelic.com/docs/apis/nerdgraph/examples/create-widgets-dashboards-api/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                New Relic's documentation
              </Link>{" "}
              for more information.
            </>
          }
        >
          <Textarea
            id="data"
            name="dataTextarea"
            value={jsonData}
            onChange={handleDataJsonChange}
            onBlur={handleBlurDashboardData}
            required
            rows={15}
            disabled={loading}
          />
          <Button
            kind="secondary"
            fill="subdued"
            size="small"
            onClick={formatJsonData}
            className="m-0 max-w-[50px]"
            aria-label="Format JSON"
            title="Format JSON"
          >
            {`{ }`}
          </Button>
        </Field>
      </Fields>

      <Buttons className="mt-2">
        <Button
          kind="destructive"
          fill="subdued"
          className="px-4"
          onClick={deleteModal.open}
          disabled={loadingMutations || loadingQueries}
        >
          Delete
        </Button>
        <div className="flex grow justify-end gap-2">
          <Button
            kind="primary"
            fill="none"
            className="px-4"
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
          <Button
            kind="primary"
            className="px-4"
            type="submit"
            loading={loadingUpdate}
            disabled={
              dashboardDataError !== "" || loadingTemplate || loadingDelete
            }
          >
            Save
          </Button>
        </div>
      </Buttons>
      <Modal open={deleteModal.isOpen} onClose={deleteModal.close} size="md">
        <div className="mb-4 flex flex-col gap-2 text-base">
          <div className="">Are you sure you want to delete this template?</div>
          <div className="font-bold text-subdued">
            This action cannot be undone.
          </div>
        </div>
        <Buttons align="right">
          <Button
            kind="destructive"
            fill="subdued"
            onClick={handleClickDelete}
            loading={loadingQueries || loadingMutations}
            aria-label="Confirm Delete"
          >
            Delete
          </Button>
          <Button kind="secondary" fill="subdued" onClick={deleteModal.close}>
            Cancel
          </Button>
        </Buttons>
      </Modal>
    </form>
  );
};
