import { useMutation } from "@tanstack/react-query";
import { print } from "graphql";

import { gql } from "@m/api/v4";
import { useAuth } from "@m/login";
import { toast } from "@m/ui";

import { V4_GRAPHQL_URL } from "@atlas/constants";

import { AwsAccountsParams, getAwsAccountsWhereClause } from "./useAwsAccounts";

export const GET_GET_AWS_ACCOUNTS_CSV = gql(/* GraphQL */ `
  query AwsAccountsCsv($sort: [AwsAccountSortEnum], $where: AwsAccountFilter) {
    awsAccounts(sort: $sort, where: $where) @csv {
      edges {
        node {
          AccountId: accountId
          AccountName: name
          Company: company {
            Name: name
          }
          AWSOrgAccountType: awsOrgAccountType
          PayerAccountId: rootAwsAccountId
          RootEmail: email
          DateAdded: added
          Company: company {
            MCN: mcn
          }
          AWSSupportPlan: supportPlan
        }
      }
    }
  }
`);

export const useExportAwsAccounts = (queryParams: AwsAccountsParams) => {
  const { user } = useAuth();

  const exportFilename = `aws-accounts-summary.csv`.toLowerCase();

  return useMutation({
    mutationFn: () => exportAwsAccounts(exportFilename, queryParams),
    onSuccess: () =>
      toast.success(
        `Your report is being generated and will be emailed to ${user.email} shortly.`,
        { autoClose: false }
      ),
    onError: () =>
      toast.error("There was a problem. Please, try again later.", {
        autoClose: false,
      }),
  });
};

const exportAwsAccounts = async (
  exportFilename: string,
  queryParams: AwsAccountsParams
) => {
  const where = getAwsAccountsWhereClause(queryParams);

  const response = await fetch(V4_GRAPHQL_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": localStorage.getItem("csrf"),
      "X-EXPORT-ME": exportFilename,
    },
    credentials: "include",
    body: JSON.stringify({
      query: print(GET_GET_AWS_ACCOUNTS_CSV),
      variables: {
        sort: queryParams.sort,
        where,
      },
    }),
  });

  const body = await response.json();

  if (!response.ok) throw new Error(body.message);

  return body;
};
