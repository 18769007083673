import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import {
  BooleanParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { AccessRequired } from "@m/api/components";
import { Button, useModalState } from "@m/ui";

import { PageTitle } from "@atlas/components/PageTitle";
import { ATLAS_MUTATIONS } from "@atlas/constants";

import { useExportAwsAccounts } from "../api";
import {
  AwsAccountAddModal,
  AwsAccountsTable,
  DEFAULT_SORT,
} from "../components";

export const AwsAccountsPage = () => {
  const addAccountModal = useModalState();

  const [queryParams] = useQueryParams({
    payersOnly: withDefault(BooleanParam, false),
    search: StringParam,
    sort: withDefault(StringParam, DEFAULT_SORT),
    status: StringParam,
  });

  const { mutateAsync: startExport, isPending: isExportLoading } =
    useExportAwsAccounts(queryParams);

  return (
    <main className="flex flex-col gap-3 p-3">
      <PageTitle
        title="AWS Accounts"
        actions={
          <div className="flex gap-2">
            <Button
              data-testid="export-accounts-button"
              kind="primary"
              fill="subdued"
              size="small"
              className=""
              leftIcon={ArrowUpTrayIcon}
              loading={isExportLoading}
              onClick={startExport}
            >
              Export
            </Button>
            <AccessRequired mutation={ATLAS_MUTATIONS.UPDATE_AWS_ACCOUNT}>
              <Button
                kind="primary"
                size="small"
                onClick={addAccountModal.open}
              >
                Add Account
              </Button>
            </AccessRequired>
          </div>
        }
      />
      <AwsAccountsTable />
      {addAccountModal.isOpen && (
        <AwsAccountAddModal onClose={addAccountModal.close} />
      )}
    </main>
  );
};
