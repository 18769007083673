import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

import { GET_NEW_RELIC_ALERT_CONDITION_TEMPLATES } from "./useNewRelicAlertConditionTemplates";

export const DELETE_NEW_RELIC_ALERT_CONDITION_TEMPLATE = gql(/* GraphQL */ `
  mutation DeleteNewRelicConditionTemplate($id: ID!) {
    deleteNewRelicConditionTemplate(id: $id) {
      ok
      message
    }
  }
`);

export const useDeleteNewRelicAlertConditionTemplate = () => {
  const [mutate, result] = useMutation(
    DELETE_NEW_RELIC_ALERT_CONDITION_TEMPLATE
  );

  const deleteNewRelicAlertConditionTemplate = (id: string) => {
    return mutate({
      variables: { id },

      onError: () =>
        toast.error("There was a problem. Please try again later."),
      onCompleted: (data) => {
        if (data.deleteNewRelicConditionTemplate.ok) {
          toast.success("Template deleted successfully.");
        }
      },
      refetchQueries: [{ query: GET_NEW_RELIC_ALERT_CONDITION_TEMPLATES }],
    });
  };

  return [deleteNewRelicAlertConditionTemplate, result] as const;
};
