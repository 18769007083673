import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Buttons,
  Checkbox,
  Field,
  Fields,
  Input,
  Link,
  Modal,
  Select,
  Textarea,
  useModalState,
} from "@m/ui";

import { PageTitle } from "@atlas/components";

import {
  useDeleteNewRelicAlertConditionTemplate,
  useNewRelicAlertConditionTemplate,
  useNewRelicPolicyTemplates,
  useUpdateNewRelicAlertConditionTemplate,
} from "../api";

export const NewRelicAlertConditionTemplateEditPage = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const navigate = useNavigate();

  // grab existing alert condition template data
  const {
    data: { alertConditionTemplate },
    loading: templateLoading,
  } = useNewRelicAlertConditionTemplate(templateId);

  // grab all policy templates
  const {
    data: { policyTemplates },
    loading: policyTemplatesLoading,
  } = useNewRelicPolicyTemplates();

  const [updateAlertConditionTemplate, { loading: loadingUpdate }] =
    useUpdateNewRelicAlertConditionTemplate();

  const [deleteAlertConditionTemplate, { loading: loadingDelete }] =
    useDeleteNewRelicAlertConditionTemplate();

  const deleteModal = useModalState();

  const [name, setName] = useState<string>(alertConditionTemplate?.name ?? "");
  const [selectedPolicy, setSelectedPolicy] = useState<string>(
    alertConditionTemplate?.policyTemplate?.id ?? ""
  );
  const [jsonData, setJsonData] = useState<string>(
    alertConditionTemplate?.data ?? "{}"
  );
  const [isDefault, setIsDefault] = useState<boolean>(
    alertConditionTemplate?.default ?? false
  );

  const loadingQueries = templateLoading || policyTemplatesLoading;
  const loadingMutations = loadingUpdate || loadingDelete;

  useEffect(() => {
    if (alertConditionTemplate?.policyTemplate?.id && !loadingQueries) {
      setSelectedPolicy(alertConditionTemplate.policyTemplate.id);

      const unformattedData = alertConditionTemplate.data ?? "{}";

      setJsonData(unformattedData);
      setIsDefault(alertConditionTemplate.default);
      setName(alertConditionTemplate.name);
    }
  }, [alertConditionTemplate, loadingQueries]);

  const [conditionDataError, setConditionDataError] = useState<string>("");

  const returnToAlertConditions = () => navigate("../");

  const validateJsonData = (data: string) => {
    try {
      const parsedData = JSON.parse(data);
      if (typeof parsedData !== "object" || parsedData === null) {
        throw new Error();
      }
    } catch (error) {
      throw new Error("Alert condition data must be a valid JSON object");
    }

    setConditionDataError("");
  };

  const formatJsonData = (): string => {
    try {
      validateJsonData(jsonData);
      setJsonData(JSON.stringify(JSON.parse(jsonData), null, 2));
    } catch (error) {
      return jsonData;
    }
  };

  const handleClickCancel = () => returnToAlertConditions();
  const handleClickDelete = () => {
    deleteAlertConditionTemplate(templateId).then((onCompleted) => {
      if (onCompleted) {
        deleteModal.close();
        returnToAlertConditions();
      }
    });
  };
  const handleNameChange = (e: FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleIsDefaultCheckboxChange = (e: FormEvent<HTMLInputElement>) => {
    setIsDefault(e.currentTarget.checked);
  };
  const handleBlurConditionData = (e: FormEvent<HTMLTextAreaElement>) => {
    const dataTextarea = e.currentTarget;

    try {
      validateJsonData(dataTextarea.value);
    } catch (error) {
      setConditionDataError(error.message);
    }
  };

  const handleDataJsonChange = (e: FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setJsonData(value);
  };

  const handleSubmit = (e: FormEvent<AlertConditionEditForm>) => {
    e.preventDefault();
    try {
      validateJsonData(jsonData);
      updateAlertConditionTemplate({
        name,
        policyTemplateId: selectedPolicy,
        data: jsonData,
        default: isDefault,
        id: templateId,
      }).then(returnToAlertConditions);
    } catch (error) {
      setConditionDataError(error.message);
      return;
    }
  };

  return (
    <form className="mb-3 flex flex-col gap-2 px-1" onSubmit={handleSubmit}>
      <PageTitle title="Edit Alert Condition" />

      <Fields className="flex flex-col">
        <div className="flex gap-3">
          <Field
            className="flex-1"
            description="This name exists to identify the alert condition in Atlas."
            htmlFor="name"
            label="Name"
          >
            <Input
              id="name"
              name="nameInput"
              required
              disabled={loadingQueries}
              value={name}
              onChange={(e) => handleNameChange(e)}
            />
          </Field>

          <Field
            className="flex-1"
            description="The policy that this alert condition will be associated with."
            htmlFor="policy"
            label="Policy"
          >
            <Select
              id="policy"
              name="policySelect"
              required
              disabled={loadingQueries}
              value={selectedPolicy}
              onChange={(e) => setSelectedPolicy(e.target.value)}
              options={policyTemplates.map((policy) => (
                <option key={policy.id} value={policy.id}>
                  {policy.name}
                </option>
              ))}
            />
          </Field>
        </div>
        <div className="flex flex-col">
          <Checkbox
            description="This will cause the alert condition to be automatically selected when setting up new accounts."
            id="default"
            label="Default Alert Condition"
            name="defaultCheckbox"
            onChange={handleIsDefaultCheckboxChange}
            checked={isDefault}
            disabled={loadingQueries}
          />
        </div>
        <div>
          <h3 className="mb-1 text-subdued">
            The following template variables are available for all string
            values:
          </h3>
          <ul className="list-inside list-disc">
            <li>
              <span className="font-mono font-bold">{"${accountId}"}</span> -
              New Relic account ID
            </li>
            <li>
              <span className="font-mono font-bold">{"${customerName}"}</span> -
              The customer's name
            </li>
            <li>
              <span className="font-mono font-bold">{"${appIds}"}</span> - List
              of Application IDs from the account
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <span className="font-mono font-semibold text-action">
                account_id
              </span>{" "}
              fields with a value of{" "}
              <span className="font-mono font-bold">{"${accountId}"}</span> will
              automatically be converted to an integer.
            </li>
            <li>
              <span className="font-mono font-semibold text-action">
                entity_ids
              </span>{" "}
              fields with a value of{" "}
              <span className="font-mono font-bold">{"${appIds}"}</span> will
              automatically be converted to a list of integers.
            </li>
            <li>
              Omit{" "}
              <span className="font-mono font-semibold text-action">
                policy_id
              </span>{" "}
              as it will be overwritten automatically
            </li>
          </ul>
        </div>
        <Field
          error={conditionDataError}
          htmlFor="data"
          label="Data"
          className="col-span-2"
          description={
            <>
              This is the JSON representation of the New Relic alert condition.
              Please visit{" "}
              <Link
                href="https://docs.newrelic.com/docs/alerts-applied-intelligence/new-relic-alerts/rest-api-alerts/alerts-conditions-api-field-names/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                New Relic's documentation
              </Link>{" "}
              for more information.
            </>
          }
        >
          <Textarea
            id="data"
            name="dataTextarea"
            onBlur={handleBlurConditionData}
            onChange={handleDataJsonChange}
            value={jsonData}
            required={true}
            rows={5}
            disabled={loadingQueries}
          />
          <Button
            kind="secondary"
            fill="subdued"
            size="small"
            onClick={formatJsonData}
            className="m-0 max-w-[50px]"
            aria-label="Format JSON"
            title="Format JSON"
          >
            {`{ }`}
          </Button>
        </Field>
      </Fields>

      <Buttons className="mt-2">
        <Button
          kind="destructive"
          fill="subdued"
          className="px-4"
          onClick={deleteModal.open}
          disabled={loadingMutations || loadingQueries}
        >
          Delete
        </Button>
        <div className="flex grow justify-end gap-2">
          <Button
            kind="primary"
            fill="none"
            className="px-4"
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
          <Button
            kind="primary"
            className="px-4"
            type="submit"
            loading={loadingUpdate}
            disabled={loadingQueries || conditionDataError !== ""}
          >
            Save
          </Button>
        </div>
      </Buttons>
      <Modal open={deleteModal.isOpen} onClose={deleteModal.close} size="md">
        <div className="mb-4 flex flex-col gap-2 text-base">
          <div className="">Are you sure you want to delete this template?</div>
          <div className="font-bold text-subdued">
            This action cannot be undone.
          </div>
        </div>
        <Buttons align="right">
          <Button
            kind="destructive"
            fill="subdued"
            onClick={handleClickDelete}
            loading={loadingQueries || loadingMutations}
            aria-label="Confirm Delete"
          >
            Delete
          </Button>
          <Button kind="secondary" fill="subdued" onClick={deleteModal.close}>
            Cancel
          </Button>
        </Buttons>
      </Modal>
    </form>
  );
};

interface AlertConditionEditFormElements extends HTMLFormControlsCollection {
  policySelect: HTMLSelectElement;
  applyToAccountsCheckbox: HTMLInputElement;
  dataTextarea: HTMLTextAreaElement;
  defaultCheckbox: HTMLInputElement;
  nameInput: HTMLInputElement;
}

export interface AlertConditionEditForm extends HTMLFormElement {
  readonly elements: AlertConditionEditFormElements;
}
