import { Navigate, Outlet } from "react-router-dom";

import { Logout } from "@m/login";

import { PATHS } from "@atlas/constants";
import { SettingsLayout } from "@atlas/features/Settings/layouts";

import { awsAccountsRoutes } from "./awsAccountsRoutes";
import { customersRoutes } from "./customersRoutes";
import { settingsRoutes } from "./settingsRoutes";

export const authenticatedAtlasRoutes = [
  {
    path: "*",
    element: <Navigate to={PATHS.CUSTOMERS} replace />,
  },
  {
    path: "customers",
    handle: {
      crumb: () => <span>Customers</span>,
      to: PATHS.CUSTOMERS,
    },
    children: customersRoutes,
  },
  {
    path: "accounts",
    handle: {
      crumb: () => <span>AWS Accounts</span>,
      to: PATHS.AWS_ACCOUNTS,
    },
    children: awsAccountsRoutes,
  },
  {
    path: "settings",
    handle: {
      crumb: () => <span>Settings</span>,
      to: PATHS.SETTINGS,
    },
    element: (
      <SettingsLayout>
        <Outlet />
      </SettingsLayout>
    ),
    children: settingsRoutes,
  },
  {
    path: "logout",
    element: <Logout />,
  },
];
