import { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";

import { Badge, Suspensed } from "@m/ui";
import { toProperCase } from "@m/utils";

import { Breadcrumbs } from "@atlas/components";

import { useCustomerBasicDetails } from "../api";
import { CustomerDetailsSidebar } from "../components";

export const CustomerDetailsLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="min-w-screen flex h-full min-h-screen w-full flex-col p-3">
      <Breadcrumbs />
      <div className="mt-2 flex min-w-[450px] grow">
        <CustomerDetailsSidebar />
        <div className="flex grow flex-col overflow-auto">{children}</div>
      </div>
    </div>
  );
};

CustomerDetailsLayout.Crumb = function Crumb() {
  const { companySlug = "" } = useParams<{ companySlug: string }>();
  const { data: { name = null, status = null } = {}, loading } =
    useCustomerBasicDetails(companySlug);

  const companyName = name ?? "Company";
  const badgeStatus: "active" | "negative" =
    status === "Active" ? "active" : "negative";

  return (
    <Suspensed loading={loading} width="200px" height="20px">
      <span className="flex items-center gap-1">
        {companyName}
        <Badge
          label={toProperCase(status ?? "")}
          size="small"
          status={badgeStatus}
        />
      </span>
    </Suspensed>
  );
};
