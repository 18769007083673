import { useQuery } from "@apollo/client";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { batchUpdate } from "@m/api";
import {
  Banner,
  Button,
  Buttons,
  Checkbox,
  Confirm,
  Field,
  Suspensed,
  toast,
  useModalState,
} from "@m/ui";
import { formatDate, fromSnakeCaseToProperCase } from "@m/utils";

import { PageTitle } from "@atlas/components";

import {
  GET_NEW_RELIC_ACCOUNT_DETAILS,
  NewRelicAccountDetails,
  NewRelicCondition,
  NewRelicConditionTemplate,
  NewRelicDashboard,
  NewRelicDashboardTemplate,
  NewRelicPolicy,
  NewRelicPolicyTemplate,
  useCreateNewRelicCondition,
  useCreateNewRelicDashboard,
  useCreateNewRelicPolicy,
  useDeleteNewRelicCondition,
  useDeleteNewRelicDashboard,
  useDeleteNewRelicPolicy,
  useNewRelicAccountDetails,
  useNewRelicTemplates,
  useSyncNewRelicAccount,
  useTerminateNewRelicAccount,
  useUpdateNewRelicAccount,
} from "../api";

export const NewRelicAccountDetailsPage = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const navigate = useNavigate();

  const {
    data: { account, dashboards, conditions, policies },
    loading,
    error,
    refetch: refetchAccountDetails,
  } = useNewRelicAccountDetails(accountId);

  const {
    data: { dashboardTemplates, conditionTemplates, policyTemplates },
    loading: templatesLoading,
  } = useNewRelicTemplates();

  const [syncNewRelicAccount, { loading: syncNewRelicAccountLoading }] =
    useSyncNewRelicAccount(accountId);

  const [
    terminateNewRelicAccount,
    { loading: terminateNewRelicAccountLoading },
  ] = useTerminateNewRelicAccount(accountId);

  const [updateNewRelicAccount, { loading: updateNewRelicAccountLoading }] =
    useUpdateNewRelicAccount(accountId);

  const [createNewRelicDashboard, { loading: createNewRelicDashboardLoading }] =
    useCreateNewRelicDashboard(accountId);
  const [createNewRelicCondition, { loading: createNewRelicConditionLoading }] =
    useCreateNewRelicCondition(accountId);
  const [createNewRelicPolicy, { loading: createNewRelicPolicyLoading }] =
    useCreateNewRelicPolicy(accountId);

  const [deleteNewRelicDashboard, { loading: deleteNewRelicDashboardLoading }] =
    useDeleteNewRelicDashboard();
  const [deleteNewRelicCondition, { loading: deleteNewRelicConditionLoading }] =
    useDeleteNewRelicCondition();
  const [deleteNewRelicPolicy, { loading: deleteNewRelicPolicyLoading }] =
    useDeleteNewRelicPolicy();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const terminateModal = useModalState();

  const returnToAccounts = useCallback(() => navigate("../"), [navigate]);

  const handleTerminateAccount = () => {
    terminateNewRelicAccount().then(() => returnToAccounts());
  };

  const handleSaveAccountEdits = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const dashboardTemplateIds = [];
    const conditionTemplateIds = [];
    const policyTemplateIds = [];

    for (const [key] of formData.entries()) {
      if (key.startsWith("Dashboards")) {
        dashboardTemplateIds.push(key.split("-")[1]);
      } else if (key.startsWith("Conditions")) {
        conditionTemplateIds.push(key.split("-")[1]);
      } else if (key.startsWith("Policies")) {
        policyTemplateIds.push(key.split("-")[1]);
      }
    }

    /**
     * Create new resources that aren't already present in the account
     */
    await createResources(
      "Dashboards",
      dashboards,
      dashboardTemplateIds,
      createNewRelicDashboard
    );
    await createResources(
      "Conditions",
      conditions,
      conditionTemplateIds,
      createNewRelicCondition
    );
    await createResources(
      "Policies",
      policies,
      policyTemplateIds,
      createNewRelicPolicy
    );

    /**
     * Delete existing resources that are no longer present in the account
     */
    await deleteResources(
      "Dashboards",
      dashboards,
      dashboardTemplateIds,
      deleteNewRelicDashboard
    );
    await deleteResources(
      "Conditions",
      conditions,
      conditionTemplateIds,
      deleteNewRelicCondition
    );
    await deleteResources(
      "Policies",
      policies,
      policyTemplateIds,
      deleteNewRelicPolicy
    );

    /**
     * Update the account's automatic syncing state if it has changed
     */
    const isAutomaticSyncingEnabled =
      account?.syncState?.toLowerCase() === "enabled";

    const autoSync = formData.get("enable-auto-sync") === "on";

    if (autoSync !== isAutomaticSyncingEnabled) {
      await updateNewRelicAccount(autoSync);
    }

    await refetchAccountDetails();
    setIsEditing(false);
  };

  useEffect(() => {
    if (error || account === null) returnToAccounts();
  }, [error, account, returnToAccounts]);

  // If any resources are present, we consider the account setup
  const isAccountSetup =
    dashboards.length > 0 || conditions.length > 0 || policies.length > 0;

  const isPending = account?.syncState === "PENDING_EMAIL_VERIFICATION";

  const editAccountLoading =
    createNewRelicDashboardLoading ||
    createNewRelicConditionLoading ||
    createNewRelicPolicyLoading ||
    deleteNewRelicDashboardLoading ||
    deleteNewRelicConditionLoading ||
    deleteNewRelicPolicyLoading ||
    updateNewRelicAccountLoading;

  return (
    <form onSubmit={handleSaveAccountEdits} className="flex flex-col gap-3">
      <Suspensed loading={loading}>
        <PageTitle
          title={account?.name}
          description="Account Settings"
          actions={
            <Buttons>
              <Button
                kind="primary"
                fill="subdued"
                size="small"
                onClick={() => setIsEditing(true)}
                disabled={isEditing || isPending}
              >
                Edit
              </Button>
              <Button
                kind="primary"
                fill="subdued"
                size="small"
                to="./domain-monitors"
                disabled={isPending}
              >
                Domain Monitors
              </Button>
              <Button
                kind="primary"
                fill="subdued"
                rightIcon={ArrowPathRoundedSquareIcon}
                size="small"
                onClick={syncNewRelicAccount}
                loading={syncNewRelicAccountLoading}
                disabled={isPending}
              >
                Sync
              </Button>
            </Buttons>
          }
        />
      </Suspensed>

      <Suspensed loading={loading} width="30%">
        <div className="flex gap-2">
          <Field
            className="w-fit whitespace-nowrap"
            label="ID"
            labelId="account-id"
            row
          >
            <span
              aria-labelledby="account-id"
              className="text-sm font-semibold text-subdued"
            >
              {accountId}
            </span>
          </Field>
          <Field
            className="w-fit whitespace-nowrap "
            label="Last Synced"
            labelId="last-sync"
            row
          >
            <span
              aria-labelledby="last-sync"
              className="text-sm font-semibold text-subdued"
            >
              {account?.lastSynced
                ? formatDate(account.lastSynced, { longFormat: true })
                : "Never"}
            </span>
          </Field>
          <Field
            className="w-fit whitespace-nowrap "
            label="Sync State"
            labelId="sync-state"
            row
          >
            <span
              aria-labelledby="sync-state"
              className="text-sm font-semibold text-subdued"
            >
              {fromSnakeCaseToProperCase(account?.syncState)}
            </span>
          </Field>
        </div>
      </Suspensed>

      {isEditing && (
        <Banner
          status={isAccountSetup ? "warning" : "info"}
          label={
            isAccountSetup ? (
              <>
                When automatic syncing is enabled, any resources you uncheck
                below will be removed from this account.
              </>
            ) : (
              <>
                This account needs to be configured. We've pre-selected some
                recommended defaults to help you get started. You can customize
                these settings as needed. Note that automatic syncing must be
                enabled before you can add resources to this account.
              </>
            )
          }
        />
      )}

      <Suspensed loading={loading} width="30%">
        <AutomaticSyncingForm account={account} isEditing={isEditing} />
      </Suspensed>

      <Suspensed
        loading={loading || templatesLoading}
        width="30%"
        height="5rem"
      >
        <SelectResourcesForm
          isAccountSetup={isAccountSetup}
          isEditing={isEditing}
          resourceTemplates={dashboardTemplates}
          resourceType="Dashboards"
          resources={dashboards}
        />
      </Suspensed>

      <Suspensed
        loading={loading || templatesLoading}
        width="30%"
        height="5rem"
      >
        <SelectResourcesForm
          isAccountSetup={isAccountSetup}
          isEditing={isEditing}
          resourceTemplates={conditionTemplates}
          resourceType="Conditions"
          resources={conditions}
        />
      </Suspensed>

      <Suspensed
        loading={loading || templatesLoading}
        width="30%"
        height="5rem"
      >
        <SelectResourcesForm
          isAccountSetup={isAccountSetup}
          isEditing={isEditing}
          resourceTemplates={policyTemplates}
          resourceType="Policies"
          resources={policies}
        />
      </Suspensed>

      <Buttons className="mb-3 justify-between">
        <Button
          kind="destructive"
          fill="subdued"
          onClick={terminateModal.open}
          disabled={isPending}
        >
          Terminate
        </Button>
        {isEditing ? (
          <span>
            <Button
              kind="primary"
              fill="none"
              className="px-4"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
            <Button
              kind="primary"
              className="px-4"
              type="submit"
              loading={editAccountLoading}
            >
              Save
            </Button>
          </span>
        ) : (
          <Button kind="primary" fill="none" to="../">
            Go Back
          </Button>
        )}
      </Buttons>

      <Confirm
        open={terminateModal.isOpen}
        onClose={terminateModal.close}
        actions={
          <Buttons>
            <Button
              kind="primary"
              fill="none"
              size="small"
              onClick={terminateModal.close}
            >
              Cancel
            </Button>
            <Button
              kind="primary"
              onClick={handleTerminateAccount}
              size="small"
              loading={terminateNewRelicAccountLoading}
            >
              Terminate
            </Button>
          </Buttons>
        }
      >
        Are you sure you want to terminate this account?
        <br />
        <strong>This action can't be undone.</strong>
      </Confirm>
    </form>
  );
};

const AutomaticSyncingForm = ({
  account,
  isEditing,
}: {
  account: NewRelicAccountDetails;
  isEditing: boolean;
}) => {
  const isAutomaticSyncingEnabled =
    account?.syncState?.toLowerCase() === "enabled";

  const [autoSync, setAutoSync] = useState<boolean>(isAutomaticSyncingEnabled);

  useEffect(
    function handleResetAutoSync() {
      if (!isEditing && autoSync !== isAutomaticSyncingEnabled) {
        setAutoSync(isAutomaticSyncingEnabled);
      }
    },
    [isEditing, isAutomaticSyncingEnabled, autoSync]
  );

  return (
    <div className="flex flex-col gap-2">
      <Checkbox
        checked={autoSync}
        disabled={!isEditing}
        id="enable-auto-sync"
        label="Enable automatic syncing"
        name="enable-auto-sync"
        onClick={() => setAutoSync((prev) => !prev)}
      />
    </div>
  );
};

const SelectResourcesForm = ({
  isAccountSetup,
  isEditing,
  resourceTemplates,
  resourceType,
  resources,
}: {
  isAccountSetup: boolean;
  isEditing: boolean;
  resourceTemplates: ResourceTemplate[];
  resourceType: ResourceType;
  resources: Resource[];
}) => {
  const resourceTemplateIds = resources.map((resource) => resource.template.id);

  const [selectedResources, setSelectedResources] =
    useState<string[]>(resourceTemplateIds);
  const [isDefaultsSelected, setIsDefaultsSelected] = useState<boolean>(false);

  useEffect(
    function handleSelectDefaultResources() {
      if (isEditing && !isAccountSetup && !isDefaultsSelected) {
        const defaultTemplateIds = resourceTemplates
          .filter((template) => template.default)
          .map((template) => template.id);
        setSelectedResources(defaultTemplateIds);
        setIsDefaultsSelected(true);
      }
    },
    [isEditing, isAccountSetup, resourceTemplates, isDefaultsSelected]
  );

  useEffect(
    function handleResetSelectedResources() {
      const isDirty =
        !resourceTemplateIds.every((id) => selectedResources.includes(id)) ||
        !selectedResources.every((id) => resourceTemplateIds.includes(id));

      if (!isEditing && isDirty) {
        setSelectedResources(resourceTemplateIds);
      }
    },
    [isEditing, selectedResources, resourceTemplateIds]
  );

  const handleClick = (templateId: string) => {
    setSelectedResources((prev) =>
      prev.includes(templateId)
        ? prev.filter((id) => id !== templateId)
        : [...prev, templateId]
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="text-sm font-semibold text-subdued">
        Select {resourceType}
      </div>
      <div>
        {resourceTemplates.map((template) => (
          <Checkbox
            checked={selectedResources.includes(template.id)}
            disabled={!isEditing}
            id={`${resourceType}-${template.id}`}
            key={template.id}
            label={template.name}
            name={`${resourceType}-${template.id}`}
            onClick={() => handleClick(template.id)}
          />
        ))}
      </div>
    </div>
  );
};

NewRelicAccountDetailsPage.Crumb = function Crumb() {
  const { accountId } = useParams<{ accountId: string }>();

  const { data, loading } = useQuery(GET_NEW_RELIC_ACCOUNT_DETAILS, {
    variables: { accountId },
    fetchPolicy: "cache-first",
  });

  const accountName = data?.newRelicAccount?.name ?? "Account";

  return (
    <Suspensed loading={loading} width="200px" height="20px">
      {accountName}
    </Suspensed>
  );
};

type ResourceType = "Dashboards" | "Conditions" | "Policies";

type ResourceTemplate =
  | NewRelicDashboardTemplate
  | NewRelicConditionTemplate
  | NewRelicPolicyTemplate;

type Resource = NewRelicDashboard | NewRelicCondition | NewRelicPolicy;

const createResources = async (
  resourceType: ResourceType,
  resources: Resource[],
  resourceTemplateIds: string[],
  createResource: (templateId: string) => Promise<any>
) => {
  const resourcesToCreate = resourceTemplateIds.filter(
    (id) => !resources.map((resource) => resource.template.id).includes(id)
  );

  const responses = await batchUpdate(resourcesToCreate, createResource);

  const successCount = responses.filter(
    (response) =>
      response.status === "fulfilled" &&
      response.value.result.data[createResource.name].ok === true
  ).length;
  const failedCount = responses.filter(
    (response) =>
      response.status === "rejected" ||
      response.value.result.data[createResource.name].ok === false
  ).length;

  if (successCount > 0)
    toast.success(`${resourceType} assigned successfully: ${successCount}`);
  if (failedCount > 0)
    toast.error(`${resourceType} assignment failed: ${failedCount}`);
};

const deleteResources = async (
  resourceType: ResourceType,
  resources: Resource[],
  resourceTemplateIds: string[],
  deleteResource: (resourceId: string) => Promise<any>
) => {
  const resourcesToDelete = resources
    .filter((resource) => !resourceTemplateIds.includes(resource.template.id))
    .map((resource) => resource.id);

  const responses = await batchUpdate(resourcesToDelete, deleteResource);

  const successCount = responses.filter(
    (response) =>
      response.status === "fulfilled" &&
      response.value.result.data[deleteResource.name].ok === true
  ).length;
  const failedCount = responses.filter(
    (response) =>
      response.status === "rejected" ||
      response.value.result.data[deleteResource.name].ok === false
  ).length;

  if (successCount > 0)
    toast.success(`${resourceType} deleted successfully: ${successCount}`);
  if (failedCount > 0)
    toast.error(`${resourceType} deletion failed: ${failedCount}`);
};
