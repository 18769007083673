import { AccessRequired } from "@m/api/components";

import { SidebarGroup, SidebarLink } from "@atlas/components/AtlasSidebar";
import { ATLAS_FEATURE_FLAGS } from "@atlas/constants";

export const SettingsSidebar = () => {
  return (
    <nav data-testid="sidebar" className="mr-3 min-w-[160px]">
      <menu className="flex flex-col gap-3">
        <SidebarGroup title="My Account">
          <SidebarLink title="Access Tokens" to="./access-tokens" />
        </SidebarGroup>

        <AccessRequired feature={ATLAS_FEATURE_FLAGS.RELEASE_PORTAL_REDESIGN}>
          <SidebarGroup title="New Relic">
            <SidebarLink title="Alert Conditions" to="./alert-conditions" />
            <SidebarLink title="Dashboards" to="./dashboards" />
          </SidebarGroup>
        </AccessRequired>
      </menu>
    </nav>
  );
};
