import { PropsWithChildren } from "react";

import { ApiProvider, ExtensionsProvider } from "@m/api";
import { useAuth } from "@m/login";

import { AtlasLogin } from "@atlas/app/components";
import { DebugConsole } from "@atlas/components";
import { cacheConfig } from "@atlas/constants";

export const AuthenticatedProviders = ({ children }: PropsWithChildren) => {
  const { user, logout } = useAuth();

  if (!user) return <AtlasLogin />;

  return (
    <ApiProvider
      cacheConfig={cacheConfig}
      context={{ clientName: "v4" }}
      logout={logout}
    >
      <ExtensionsProvider>
        {children}
        <DebugConsole />
      </ExtensionsProvider>
    </ApiProvider>
  );
};
