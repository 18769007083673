import { createContext, useContext } from "react";

import { ApiExtensions } from "../types";

export const ExtensionsContext = createContext<{
  extensions: ApiExtensions | null;
  resetExtensions?: () => void;
}>({
  extensions: null,
  resetExtensions: undefined,
});

export const useExtensions = () => useContext(ExtensionsContext);
