import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

import { GET_AWS_ACCOUNTS_QUERY } from "./useAwsAccounts";

export const UPDATE_AWS_ACCOUNT_MUTATION = gql(/* GraphQL */ `
  mutation UpdateAwsAccount($input: UpdateAwsAccountInput!) {
    updateAwsAccount(input: $input) {
      ok
      message
      awsAccount {
        accountId
        name
      }
    }
  }
`);

interface UpdateAwsAccountInput {
  id: string;
  companyId: string;
  externalId: string;
  payerType: string;
}

export const useUpdateAwsAccount = () => {
  const [mutate, result] = useMutation(UPDATE_AWS_ACCOUNT_MUTATION, {
    refetchQueries: [GET_AWS_ACCOUNTS_QUERY],
    onError: () => toast.error("Unable to update AWS account"),
    onCompleted: (data) => {
      if (!data.updateAwsAccount.ok) {
        toast.error(data.updateAwsAccount.message);
      } else {
        const { accountId, name } = data.updateAwsAccount.awsAccount;
        toast.success(`AWS account updated: ${name || accountId}`);
      }
    },
  });

  const updateAwsAccount = ({
    id,
    companyId,
    externalId,
    payerType,
  }: UpdateAwsAccountInput) => {
    return mutate({
      variables: {
        input: { id, companyId, externalId, payerType, isCreate: false },
      },
    });
  };

  return [updateAwsAccount, result] as const;
};
