import { useMemo } from "react";

import { MarketplaceMeteringMethod, MeteredCharges } from "@m/api/public/types";
import { Table } from "@m/ui";

import { MeteredChargesDateTooltip } from "../components";

export const MeteredChargesTable = ({
  meteredCharges,
  dimensions,
  isLoading,
  ...props
}: {
  meteredCharges: MeteredCharges[];
  dimensions: {
    id: string;
    displayName: string;
    dimensionTypeId: string;
    meteringMethod: MarketplaceMeteringMethod;
  }[];
  isLoading: boolean;
}) => {
  const rows = useMemo(() => {
    const createDateTooltip = (isPending: boolean, timestamp: string) => {
      if (!timestamp) {
        return "-";
      }

      return (
        <MeteredChargesDateTooltip
          isPending={isPending}
          timestamp={timestamp}
        />
      );
    };

    return meteredCharges.map((meteredCharge) => {
      const isChargePending =
        meteredCharge?.usageDate && !meteredCharge?.chargeDate;

      const dimensionDisplayName =
        (dimensions || []).find(
          (dimension) => dimension.dimensionTypeId === meteredCharge?.dimension
        )?.displayName || "-";

      return {
        dimension: dimensionDisplayName,
        usageDate: createDateTooltip(isChargePending, meteredCharge?.usageDate),
        units: meteredCharge?.units || "-",
        chargeDate: createDateTooltip(
          isChargePending,
          meteredCharge?.chargeDate
        ),
        chargedBy: meteredCharge?.chargedBy || "Automatic",
      };
    });
  }, [meteredCharges, dimensions]);

  return (
    <Table
      headerFontSize="small"
      headers={TABLE_HEADERS}
      loading={isLoading}
      rows={rows}
      emptyMessage="No transactions found"
      {...props}
    />
  );
};

const TABLE_HEADERS = [
  { label: "Pricing Dimension (Fee)", accessor: "dimension" },
  { label: "Usage Date", accessor: "usageDate" },
  {
    label: "Units",
    accessor: "units",
  },
  {
    label: "Charge Date",
    accessor: "chargeDate",
  },
  {
    label: "Charged By",
    accessor: "chargedBy",
  },
];
