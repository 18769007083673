export const LOGIN_ERROR =
  "We encountered a problem logging you into your account.";

export const MAINTENANCE_ERROR = (
  <>
    <p>Mission Control is temporarily undergoing maintenance.</p>
    <p>
      Check{" "}
      <a
        href="https://status.missioncloud.com"
        target="_blank"
        rel="noreferrer"
        className="text-yellow-300 underline"
      >
        https://status.missioncloud.com
      </a>{" "}
      for updates.
    </p>
  </>
);
