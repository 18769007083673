import {
  ApolloClient,
  ApolloProvider,
  Context,
  InMemoryCache,
  InMemoryCacheConfig,
  from,
} from "@apollo/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode, useState } from "react";

import {
  customHeadersMiddleware,
  httpLink,
  makeExtensionsLink,
  makeHttpErrorLink,
} from "@m/api/utils";

import { DEFAULT_OPTIONS } from "../constants";
import { ExtensionsContext } from "../contexts";
import { ApiExtensions } from "../types";

interface ApiProviderProps {
  cacheConfig: InMemoryCacheConfig;
  children: ReactNode;
  context?: Context;

  /** Function used to logout of the application */
  logout?: () => void;

  /** Function called when API is in maintenance mode */
  onMaintenanceResponse?: () => void;
}

const queryClient = new QueryClient();

export const ApiProvider = ({
  cacheConfig,
  children,
  context,
  logout,
  onMaintenanceResponse,
}: ApiProviderProps) => {
  const [extensions, setExtensions] = useState<ApiExtensions>(null);

  const cache = new InMemoryCache(cacheConfig);
  const apolloDevMode = process.env.NODE_ENV === "development";

  const apolloClient = new ApolloClient({
    cache,
    connectToDevTools: apolloDevMode,
    link: from([
      makeHttpErrorLink(logout, onMaintenanceResponse),
      customHeadersMiddleware,
      makeExtensionsLink(setExtensions),
      httpLink,
    ]),
    defaultOptions: {
      watchQuery: {
        ...DEFAULT_OPTIONS.watchQuery,
        context,
      },
      query: {
        ...DEFAULT_OPTIONS.query,
        context,
      },
      mutate: {
        ...DEFAULT_OPTIONS.mutate,
        context,
      },
    },
  });

  const rbac = extensions?.rbac;
  let featureFlags = extensions?.featureFlags;

  /**
   * Feature flags can be manually set in .env.development.local using
   * csv format with the REACT_APP_FEATURE_FLAGS environment variable
   *
   * e.g. REACT_APP_FEATURE_FLAGS=flag1,flag2,flag3
   */
  if (
    process.env.REACT_APP_FEATURE_FLAGS &&
    process.env.NODE_ENV === "development" &&
    !process.env.REACT_APP_STORYBOOK_SERVICES_API
  ) {
    featureFlags = process.env.REACT_APP_FEATURE_FLAGS.split(",");
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <ExtensionsContext.Provider
          value={{
            extensions: extensions ? { rbac, featureFlags } : null,
            resetExtensions: () => setExtensions(null),
          }}
        >
          {children}
        </ExtensionsContext.Provider>
      </ApolloProvider>
    </QueryClientProvider>
  );
};
