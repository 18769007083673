import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { UpdateNewRelicConditionTemplateInput } from "@m/api/v4/types";
import { toast } from "@m/ui";

import { GET_NEW_RELIC_ALERT_CONDITION_TEMPLATES } from "./useNewRelicAlertConditionTemplates";

export const UPDATE_NEW_RELIC_ALERT_CONDITION_TEMPLATE = gql(/* GraphQL */ `
  mutation UpdateNewRelicConditionTemplate(
    $input: UpdateNewRelicConditionTemplateInput!
  ) {
    updateNewRelicConditionTemplate(input: $input) {
      message
      ok
    }
  }
`);

export const useUpdateNewRelicAlertConditionTemplate = () => {
  const [mutate, result] = useMutation(
    UPDATE_NEW_RELIC_ALERT_CONDITION_TEMPLATE,
    {
      refetchQueries: [GET_NEW_RELIC_ALERT_CONDITION_TEMPLATES],
      onError: () =>
        toast.error("Failed to update New Relic alert condition template."),
      onCompleted: (data) => {
        if (data.updateNewRelicConditionTemplate.ok) {
          toast.success(
            "New Relic alert condition template updated successfully."
          );
        } else {
          toast.error(data.updateNewRelicConditionTemplate.message);
        }
      },
    }
  );

  const updateNewRelicAlertConditionTemplate = (
    input: UpdateNewRelicConditionTemplateInput
  ) => {
    return mutate({ variables: { input } });
  };

  return [updateNewRelicAlertConditionTemplate, result] as const;
};
