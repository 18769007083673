import { useMemo } from "react";

import { MarketplaceDimension } from "@m/api/public/types";
import { FilterBar, FilterSelect } from "@m/ui";

type QueryParamsType = {
  startDate: Date;
  endDate: Date;
  productCode: string;
  dimensionCode: string;
};
export const MeteredChargesTableFilter = ({
  query,
  dimensions,
  handleQueryChange,
  handleResetFilters,
  loading,
}: {
  query: QueryParamsType;
  dimensions: Pick<MarketplaceDimension, "displayName" | "dimensionTypeId">[];
  handleQueryChange: ({ productCode, dimensionCode }: QueryParamsType) => void;
  handleResetFilters: () => void;
  loading: boolean;
}) => {
  const [dimensionOptions, dimensionLabelMap] = useMemo(() => {
    const dimensionOptions = dimensions.map((dimension) => ({
      id: dimension.dimensionTypeId,
      label: dimension.displayName,
    }));

    const labelMap = new Map(
      [...dimensionOptions].map(
        ({ id, label }: { id: string; label: string }) => [id, label]
      )
    );

    return [dimensionOptions, labelMap];
  }, [dimensions]);

  const handleSelectDimensionsFilter = (dimensionCode: string | null) => {
    handleQueryChange({ ...query, dimensionCode });
  };

  const { dimensionCode: selectedDimensionCode } = query;

  return (
    <div className="mb-2 flex w-full flex-wrap items-center justify-between">
      <FilterBar
        isActive={true}
        onResetFilters={handleResetFilters}
        className="mb-1.5"
      >
        <FilterSelect
          ariaLabel="Dimensions Filter"
          disabled={loading}
          displayValue={
            dimensionLabelMap.get(selectedDimensionCode) ?? "All Dimensions"
          }
          initialValue="All Dimensions"
          onChange={handleSelectDimensionsFilter}
          onClear={() => {
            handleSelectDimensionsFilter(null);
          }}
          options={dimensionOptions}
          selection={selectedDimensionCode ?? "All Dimensions"}
        />
      </FilterBar>
    </div>
  );
};
