import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

import { PayerType } from "../types";

import { GET_AWS_ACCOUNTS_QUERY } from "./useAwsAccounts";

export const ADD_AWS_ACCOUNT_MUTATION = gql(/* GraphQL */ `
  mutation AddAwsAccount($input: UpdateAwsAccountInput!) {
    updateAwsAccount(input: $input) {
      ok
      message
      awsAccount {
        accountId
        name
      }
    }
  }
`);

type AddAwsAccountInput = {
  id: string;
  name: string;
  isPayer: boolean;
  companyId: string;
};

export const useAddAwsAccount = () => {
  const [mutate, result] = useMutation(ADD_AWS_ACCOUNT_MUTATION, {
    refetchQueries: [GET_AWS_ACCOUNTS_QUERY],
    onError: () => toast.error("Unable to add AWS account"),
    onCompleted: (data) => {
      if (!data.updateAwsAccount.ok) {
        toast.error(data.updateAwsAccount.message);
      } else {
        const { accountId, name } = data.updateAwsAccount.awsAccount;
        toast.success(`AWS account added: ${name || accountId}`);
      }
    },
  });

  const addAwsAccount = ({
    id,
    name,
    isPayer,
    companyId,
  }: AddAwsAccountInput) => {
    return mutate({
      variables: {
        input: {
          id,
          name,
          companyId,
          isCreate: true,
          isPayer,
          payerType: isPayer ? PayerType.Dedicated : PayerType.NotSet, // todo: remove this field once no longer required by the backend
        },
      },
    });
  };

  return [addAwsAccount, result] as const;
};
