import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApmRequirement } from "@m/api/v4/types";
import {
  Button,
  Buttons,
  Checkbox,
  DynamicSelect,
  Field,
  Fields,
  Input,
  Link,
  Textarea,
} from "@m/ui";
import { fromSnakeCaseToProperCase } from "@m/utils";

import { PageTitle } from "@atlas/components";

import { useCreateNewRelicDashboardTemplate } from "../api";

export const NewRelicDashboardTemplateCreatePage = () => {
  const navigate = useNavigate();

  const [createNewRelicDashboardTemplate, { loading }] =
    useCreateNewRelicDashboardTemplate();

  const [dashboardDataError, setDashboardDataError] = useState<string>("");

  const returnToDashboardTemplates = () => navigate("../");

  const validateDashboardDataJson = (data: string) => {
    try {
      const parsedData = JSON.parse(data);
      if (typeof parsedData !== "object" || parsedData === null) {
        throw new Error();
      }
    } catch (error) {
      throw new Error("Dashboard data must be a valid JSON object");
    }

    setDashboardDataError("");
  };

  const handleClickCancel = () => returnToDashboardTemplates();

  const handleBlurDashboardData = (e: FormEvent<HTMLTextAreaElement>) => {
    const dataTextarea = e.currentTarget;

    try {
      validateDashboardDataJson(dataTextarea.value);
    } catch (error) {
      setDashboardDataError(error.message);
    }
  };

  const handleSubmit = (e: FormEvent<NewRelicDashboardTemplateCreateForm>) => {
    e.preventDefault();
    const form = e.currentTarget;

    const {
      nameInput,
      defaultCheckbox,
      apmRequirementSelect,
      applyToAccountsCheckbox,
      dataTextarea,
    } = form.elements;

    try {
      validateDashboardDataJson(dataTextarea.value);
    } catch (error) {
      setDashboardDataError(error.message);
      return;
    }

    createNewRelicDashboardTemplate({
      apmRequirement: apmRequirementSelect.value as ApmRequirement,
      applyToAccounts: applyToAccountsCheckbox.checked,
      data: dataTextarea.value,
      default: defaultCheckbox.checked,
      name: nameInput.value,
    }).then(returnToDashboardTemplates);
  };

  return (
    <form className="mb-3 flex flex-col gap-3 px-1" onSubmit={handleSubmit}>
      <PageTitle title="Create Dashboard Template" />

      <Fields>
        <Field
          className="max-w-xl"
          description="This name exists to identify the dashboard in Atlas."
          htmlFor="name"
          label="Name"
        >
          <Input id="name" name="nameInput" required />
        </Field>

        <Checkbox
          description="This will cause the dashboard to be automatically selected when setting up new accounts."
          id="default"
          label="Default dashboard"
          name="defaultCheckbox"
        />

        <Field
          className="max-w-xl"
          description="Determines if applications must be present within the account in order for the Dashboard to be created."
          htmlFor="apm-requirement"
          label="APM Requirements"
        >
          <DynamicSelect
            id="apm-requirement"
            name="apmRequirementSelect"
            initialOptions={Object.values(ApmRequirement).map((value) => ({
              value,
              label: fromSnakeCaseToProperCase(value),
            }))}
          />
        </Field>

        <Checkbox
          description="This will create the templatized dashboard in all active New Relic accounts"
          id="apply-to-accounts"
          label="Apply to existing accounts"
          name="applyToAccountsCheckbox"
        />

        <div className="text-sm font-medium">
          <div className="mb-1 text-subdued">
            The following template variables are available for all string
            values:
          </div>

          <ul className="list-disc pl-3">
            <li>
              <span className="font-semibold">{`\${accountId}`}</span> - New
              Relic account ID
            </li>
            <li>
              <span className="font-semibold">{`\${customerName}`}</span> - The
              customer's name
            </li>
            <li>
              <span className="font-semibold">{`\${appIds}`}</span> - List of
              Application IDs from the account
            </li>
          </ul>
        </div>

        <div className="text-sm">
          <div>
            <strong>account_id</strong> fields with a value of{" "}
            {`"\${accountId}"`} will automatically be converted to an integer.
          </div>
          <div>
            <strong>entity_ids</strong> fields with a value of {`"\${appIds}"`}{" "}
            will automatically be converted to a list of integers.
          </div>
        </div>

        <Field
          error={dashboardDataError}
          htmlFor="data"
          label="Data"
          description={
            <>
              This is the JSON representation of the New Relic dashboard. Please
              visit{" "}
              <Link
                href="https://docs.newrelic.com/docs/apis/nerdgraph/examples/create-widgets-dashboards-api/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                New Relic's documentation
              </Link>{" "}
              for more information.
            </>
          }
        >
          <Textarea
            defaultValue="{}"
            id="data"
            name="dataTextarea"
            onBlur={handleBlurDashboardData}
            required
            rows={5}
          />
        </Field>
      </Fields>

      <Buttons align="right">
        <Button
          kind="primary"
          className="px-4"
          type="submit"
          loading={loading}
          disabled={dashboardDataError !== ""}
        >
          Create
        </Button>
        <Button
          kind="primary"
          fill="none"
          className="px-4"
          onClick={handleClickCancel}
        >
          Cancel
        </Button>
      </Buttons>
    </form>
  );
};

interface NewRelicDashboardTemplateCreateFormElements
  extends HTMLFormControlsCollection {
  apmRequirementSelect: HTMLSelectElement;
  applyToAccountsCheckbox: HTMLInputElement;
  dataTextarea: HTMLTextAreaElement;
  defaultCheckbox: HTMLInputElement;
  nameInput: HTMLInputElement;
}

export interface NewRelicDashboardTemplateCreateForm extends HTMLFormElement {
  readonly elements: NewRelicDashboardTemplateCreateFormElements;
}
