import { useMutation } from "@tanstack/react-query";
import { print } from "graphql";

import { gql } from "@m/api/v4";
import { useAuth } from "@m/login";
import { toast } from "@m/ui";
import { dt } from "@m/utils";

import { V4_GRAPHQL_URL } from "@atlas/constants";

export const GET_METERED_CHARGES_TRANSACTIONS_CSV = gql(/* GraphQL */ `
  query GetMarketplaceMeteredChargesCsv(
    $startDate: ArrowTypeScalar!
    $endDate: ArrowTypeScalar!
    $companyId: Int!
    $dimensionCode: String
    $productCode: String
  ) {
    meteredCharges(
      startDate: $startDate
      endDate: $endDate
      companyId: $companyId
      dimensionCode: $dimensionCode
      productCode: $productCode
    ) @csv {
      edges {
        node {
          PricingDimension: dimension
          UsageDate: usageDate
          Units: units
          ChargeDate: chargeDate
        }
      }
    }
  }
`);

interface ExportMeteredChargesQueryParams {
  startDate: Date;
  endDate: Date;
  productCode: string;
  dimensionCode?: string;
}

export const useExportMeteredCharges = (
  companyId: number,
  queryParams: ExportMeteredChargesQueryParams
) => {
  const { user } = useAuth();

  const exportFilename = `marketplace-charges-summary--${dt
    .fromJSDate(queryParams?.startDate)
    .toFormat("MMMM-yyyy")}.csv`.toLowerCase();

  return useMutation({
    mutationFn: () =>
      exportMeteredCharges(exportFilename, companyId, queryParams),
    onSuccess: () =>
      toast.success(
        `Your report is being generated and will be emailed to ${user.email} shortly.`,
        { autoClose: false }
      ),
    onError: () =>
      toast.error("There was a problem. Please, try again later.", {
        autoClose: false,
      }),
  });
};

const exportMeteredCharges = async (
  exportFilename: string,
  companyId: number,
  queryParams: ExportMeteredChargesQueryParams
) => {
  const response = await fetch(V4_GRAPHQL_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": localStorage.getItem("csrf"),
      "X-EXPORT-ME": exportFilename,
    },
    credentials: "include",
    body: JSON.stringify({
      query: print(GET_METERED_CHARGES_TRANSACTIONS_CSV),
      variables: {
        companyId,
        dimensionCode: queryParams.dimensionCode,
        endDate: queryParams.endDate,
        productCode: queryParams.productCode,
        startDate: queryParams.startDate,
      },
    }),
  });

  const body = await response.json();

  if (!response.ok) throw new Error(body.message);

  return body;
};
