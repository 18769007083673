import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { CreateNewRelicConditionTemplateInput } from "@m/api/v4/types";
import { toast } from "@m/ui";

import { GET_NEW_RELIC_ALERT_CONDITION_TEMPLATES } from "./useNewRelicAlertConditionTemplates";

export const CREATE_NEW_RELIC_ALERT_CONDITION_TEMPLATE = gql(/* GraphQL */ `
  mutation CreateNewRelicConditionTemplate(
    $input: CreateNewRelicConditionTemplateInput!
  ) {
    createNewRelicConditionTemplate(input: $input) {
      message
      ok
    }
  }
`);

export const useCreateNewRelicAlertConditionTemplate = () => {
  const [mutate, result] = useMutation(
    CREATE_NEW_RELIC_ALERT_CONDITION_TEMPLATE,
    {
      refetchQueries: [GET_NEW_RELIC_ALERT_CONDITION_TEMPLATES],
      onError: () => toast.error("Failed to create alert condition template."),
      onCompleted: (data) => {
        if (data.createNewRelicConditionTemplate.ok) {
          toast.success("Alert condition template created successfully.");
        } else {
          toast.error(data.createNewRelicConditionTemplate.message);
        }
      },
    }
  );

  const createNewRelicAlertConditionTemplate = (
    input: CreateNewRelicConditionTemplateInput
  ) => {
    return mutate({ variables: { input } });
  };

  return [createNewRelicAlertConditionTemplate, result] as const;
};
