import { useQuery } from "@apollo/client";
import { ReactNode } from "react";

import { SpinnerScreen } from "@m/ui";

import { useExtensions } from "../contexts";
import { gql } from "../public";

export const EXTENSIONS = gql(/* GraphQL */ `
  query Extensions {
    __schema {
      queryType {
        name
      }
      mutationType {
        name
      }
    }
  }
`);

export const ExtensionsProvider = ({ children }: { children: ReactNode }) => {
  const { extensions } = useExtensions();

  useQuery(EXTENSIONS, { skip: extensions !== null });

  if (extensions === null) return <SpinnerScreen />;

  return children;
};
