import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

export const DELETE_NEW_RELIC_DOMAIN_MONITOR = gql(/* GraphQL */ `
  mutation DeleteNewRelicDomainMonitor($id: ID!) {
    deleteNewRelicDomainMonitor(id: $id) {
      ok
      message
    }
  }
`);

export const useDeleteNewRelicDomainMonitor = (domainMonitorId: string) => {
  const [mutate, result] = useMutation(DELETE_NEW_RELIC_DOMAIN_MONITOR, {
    refetchQueries: ["GetNewRelicAccountDomainMonitors"],
    onError: () => toast.error("Unable to delete domain monitor."),
    onCompleted: (data) => {
      if (data.deleteNewRelicDomainMonitor.ok) {
        toast.success("Domain monitor deleted successfully.");
      } else {
        toast.error(data.deleteNewRelicDomainMonitor.message);
      }
    },
  });

  const deleteNewRelicDomainMonitor = () => {
    return mutate({ variables: { id: domainMonitorId } });
  };

  return [deleteNewRelicDomainMonitor, result] as const;
};
