import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";

export const DELETE_NEW_RELIC_CONDITION = gql(/* GraphQL */ `
  mutation DeleteNewRelicCondition($id: ID!) {
    deleteNewRelicCondition(id: $id) {
      ok
      message
    }
  }
`);

export const useDeleteNewRelicCondition = () => {
  const [mutate, result] = useMutation(DELETE_NEW_RELIC_CONDITION);

  const deleteNewRelicCondition = (conditionId: string) => {
    return mutate({ variables: { id: conditionId } });
  };

  return [deleteNewRelicCondition, result] as const;
};
