import { useParams } from "react-router-dom";

import { Button } from "@m/ui";

import { PageTitle } from "@atlas/components";

import { NewRelicDomainMonitorsTable } from "../components";

export const NewRelicAccountDomainMonitorsPage = () => {
  const { accountId } = useParams<{ accountId: string }>();
  return (
    <main className="flex flex-col gap-3">
      <PageTitle
        title="Domain Monitors"
        actions={
          <Button kind="primary" size="small" to="./create">
            Create
          </Button>
        }
      />
      <NewRelicDomainMonitorsTable accountId={accountId} />
    </main>
  );
};
