import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { Button, Tooltip } from "@m/ui";

interface CopyButtonProps {
  /** The text to copy to the clipboard */
  text: string;

  /** Whether the color of the icon should be inverted for dark backgrounds */
  inverted?: boolean;
}

export const CopyButton = ({ text, inverted = false }: CopyButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        // This will remove the "Copied!" tooltip after 2 seconds
        setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <Tooltip content="Copied!" side="right" color="light" open={isCopied}>
      <Button
        as="div"
        className={clsx({ "text-white": inverted })}
        data-testid="copy-to-clipboard"
        fill="none"
        kind="primary"
        leftIcon={DocumentDuplicateIcon}
        onClick={handleCopyToClipboard}
        size="small"
      />
    </Tooltip>
  );
};
