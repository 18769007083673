import { useNavigate } from "react-router-dom";
import { createEnumParam, useQueryParams, withDefault } from "use-query-params";

import {
  NewRelicDomainMonitorIncident,
  NewRelicDomainMonitorSortEnum,
} from "@m/api/v4/types";
import { Badge, Table, TableHeader, Timestamp } from "@m/ui";
import { toProperCase } from "@m/utils";

import { useNewRelicAccountDomainMonitors } from "../api";

interface NewRelicDomainMonitorsTableProps {
  accountId: string;
}

export const NewRelicDomainMonitorsTable = ({
  accountId,
}: NewRelicDomainMonitorsTableProps) => {
  const [query, setQuery] = useQueryParams({
    sort: withDefault(
      createEnumParam(Object.values(NewRelicDomainMonitorSortEnum)),
      DEFAULT_SORT
    ),
  });

  const navigate = useNavigate();

  const {
    data: { domainMonitors },
    pagination,
    loading,
  } = useNewRelicAccountDomainMonitors(accountId, query);

  const handleSortChange = (sort: NewRelicDomainMonitorSortEnum) => {
    pagination.setCurrentPage(1);
    setQuery({ sort });
  };

  const handleClickRow = (domainMonitorId: string) => {
    navigate(`./${domainMonitorId}`);
  };

  const rows = domainMonitors.map((domainMonitor) => ({
    domain: <DomainName domain={domainMonitor.domain} />,
    lastSync: <DomainMonitorLastSync lastSynced={domainMonitor.lastSynced} />,
    status: (
      <DomainMonitorStatus activeIncident={domainMonitor.activeIncident} />
    ),
    enabled: <DomainMonitorEnabled enabled={domainMonitor.enabled} />,
    expires: <DomainMonitorExpires expires={domainMonitor.expires} />,
    onClick: () => handleClickRow(domainMonitor.id),
  }));

  return (
    <div>
      <Table
        defaultSort={query.sort}
        emptyMessage="No domain monitors configured"
        headers={NEW_RELIC_DOMAIN_MONITORS_TABLE_HEADERS}
        loading={loading}
        onSortChange={handleSortChange}
        rows={rows}
        {...pagination}
      />
    </div>
  );
};

const DomainName = ({ domain }: { domain: string }) => (
  <div className="text-sm font-medium text-default">{domain}</div>
);

const DomainMonitorLastSync = ({ lastSynced }: { lastSynced: string | null }) =>
  lastSynced ? (
    <Timestamp
      isoTime={lastSynced}
      longFormat
      className="text-sm font-medium text-default no-underline"
    />
  ) : (
    <div className="text-sm font-medium text-default">Never</div>
  );

const DomainMonitorStatus = ({
  activeIncident,
}: {
  activeIncident: Pick<NewRelicDomainMonitorIncident, "title">;
}) =>
  activeIncident ? (
    <Badge label={toProperCase(activeIncident.title)} status="warning" />
  ) : (
    <Badge label="OK" status="positive" />
  );

const DomainMonitorEnabled = ({ enabled }: { enabled: boolean }) => (
  <div className="text-sm font-medium text-default">
    {enabled ? "Yes" : "No"}
  </div>
);

const DomainMonitorExpires = ({ expires }: { expires: string | null }) =>
  expires ? (
    <Timestamp
      isoTime={expires}
      longFormat
      className="text-sm font-medium text-default no-underline"
    />
  ) : (
    <div className="text-sm font-medium text-default">Never</div>
  );

const NEW_RELIC_DOMAIN_MONITORS_TABLE_HEADERS: TableHeader[] = [
  { label: "Domain", accessor: "domain", sort: "DOMAIN" },
  { label: "Last Checked", accessor: "lastSync", sort: "LAST_SYNCED" },
  { label: "Status", accessor: "status" },
  { label: "Enabled", accessor: "enabled" },
  { label: "Expires", accessor: "expires", sort: "EXPIRES" },
];

const DEFAULT_SORT = NewRelicDomainMonitorSortEnum.DomainAsc;
