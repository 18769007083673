import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { CreateNewRelicDashboardTemplateInput } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const CREATE_NEW_RELIC_DASHBOARD_TEMPLATE = gql(/* GraphQL */ `
  mutation CreateNewRelicDashboardTemplate(
    $input: CreateNewRelicDashboardTemplateInput!
  ) {
    createNewRelicDashboardTemplate(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateNewRelicDashboardTemplate = () => {
  const [mutate, result] = useMutation(CREATE_NEW_RELIC_DASHBOARD_TEMPLATE, {
    refetchQueries: ["GetNewRelicDashboardTemplates"],
    onError: () =>
      toast.error("Failed to create New Relic dashboard template."),
    onCompleted: (data) => {
      if (data.createNewRelicDashboardTemplate.ok) {
        toast.success("New Relic dashboard template created successfully.");
      } else {
        toast.error(data.createNewRelicDashboardTemplate.message);
      }
    },
  });

  const createNewRelicDashboardTemplate = (
    input: CreateNewRelicDashboardTemplateInput
  ) => {
    return mutate({ variables: { input } });
  };

  return [createNewRelicDashboardTemplate, result] as const;
};
