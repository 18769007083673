import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { UpdateNewRelicDashboardTemplateInput } from "@m/api/v4/types";
import { toast } from "@m/ui";

import { GET_NEW_RELIC_DASHBOARD_TEMPLATES } from "./useNewRelicDashboardTemplates";

export const UPDATE_NEW_RELIC_DASHBOARD_TEMPLATE = gql(/* GraphQL */ `
  mutation UpdateNewRelicDashboardTemplate(
    $input: UpdateNewRelicDashboardTemplateInput!
  ) {
    updateNewRelicDashboardTemplate(input: $input) {
      message
      ok
    }
  }
`);

export const useUpdateNewRelicDashboardTemplate = () => {
  const [mutate, result] = useMutation(UPDATE_NEW_RELIC_DASHBOARD_TEMPLATE, {
    refetchQueries: [GET_NEW_RELIC_DASHBOARD_TEMPLATES],
    onError: () => toast.error("Failed to update dashboard template."),
    onCompleted: (data) => {
      if (data.updateNewRelicDashboardTemplate.ok) {
        toast.success("Dashboard template updated successfully.");
      } else {
        toast.error(data.updateNewRelicDashboardTemplate.message);
      }
    },
  });

  const updateNewRelicDashboardTemplate = (
    input: UpdateNewRelicDashboardTemplateInput
  ) => {
    return mutate({ variables: { input } });
  };

  return [updateNewRelicDashboardTemplate, result] as const;
};
