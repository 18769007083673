import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { StatusCodePreference } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const UPDATE_NEW_RELIC_DOMAIN_MONITOR = gql(/* GraphQL */ `
  mutation UpdateNewRelicDomainMonitor(
    $input: UpdateNewRelicDomainMonitorInput!
  ) {
    updateNewRelicDomainMonitor(input: $input) {
      ok
      message
    }
  }
`);

export const useUpdateNewRelicDomainMonitor = (domainMonitorId: string) => {
  const [mutate, result] = useMutation(UPDATE_NEW_RELIC_DOMAIN_MONITOR, {
    refetchQueries: ["GetNewRelicDomainMonitorDetails"],
    onError: () => toast.error("Unable to update domain monitor."),
    onCompleted: (data) => {
      if (data.updateNewRelicDomainMonitor.ok) {
        toast.success("Domain monitor updated successfully.");
      } else {
        toast.error(data.updateNewRelicDomainMonitor.message);
      }
    },
  });

  const updateNewRelicDomainMonitor = ({
    domain,
    enabled,
    expireThreshold,
    name,
    statusCodeAlertPreference,
  }: {
    domain: string;
    enabled: boolean;
    expireThreshold: number;
    name: string;
    statusCodeAlertPreference: StatusCodePreference;
  }) => {
    return mutate({
      variables: {
        input: {
          domain,
          enabled,
          expireThreshold,
          id: domainMonitorId,
          name,
          statusCodeAlertPreference,
        },
      },
    });
  };

  return [updateNewRelicDomainMonitor, result] as const;
};
