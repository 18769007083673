import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Buttons,
  Checkbox,
  Field,
  Fields,
  Input,
  Link,
  Select,
  Textarea,
} from "@m/ui";

import { PageTitle } from "@atlas/components";

import {
  useCreateNewRelicAlertConditionTemplate,
  useNewRelicPolicyTemplates,
} from "../api";
import { ConditionType } from "../types";

export const NewRelicAlertConditionTemplateCreatePage = () => {
  const navigate = useNavigate();

  const [createAlertConditionTemplate, { loading: loadingCreate }] =
    useCreateNewRelicAlertConditionTemplate();

  const {
    data: { policyTemplates = [] },
    loading: loadingPolicies,
  } = useNewRelicPolicyTemplates();

  const [conditionDataError, setConditionDataError] = useState<string>("");

  const returnToAlertConditions = () => navigate("../");

  const validateConditionDataJson = (data: string) => {
    try {
      const parsedData = JSON.parse(data);
      if (typeof parsedData !== "object" || parsedData === null) {
        throw new Error();
      }
    } catch (error) {
      throw new Error("Alert condition data must be a valid JSON object");
    }

    setConditionDataError("");
  };

  const formatJsonData = (data: string): string => {
    try {
      validateConditionDataJson(data);
      return JSON.stringify(JSON.parse(data), null, 2);
    } catch (error) {
      return data;
    }
  };

  const handleClickCancel = () => returnToAlertConditions();
  const handleFormatJsonData = () => {
    const dataTextarea = document.getElementById("data") as HTMLTextAreaElement;
    if (dataTextarea) {
      const formattedData = formatJsonData(dataTextarea.value);
      dataTextarea.value = formattedData;
    }
  };

  const handleBlurConditionData = (e: FormEvent<HTMLTextAreaElement>) => {
    const dataTextarea = e.currentTarget;

    try {
      validateConditionDataJson(dataTextarea.value);
    } catch (error) {
      setConditionDataError(error.message);
    }
  };

  const handleSubmit = (e: FormEvent<AlertConditionCreateForm>) => {
    e.preventDefault();
    const form = e.currentTarget;

    const {
      nameInput,
      defaultCheckbox,
      policySelect,
      applyToAccountsCheckbox,
      dataTextarea,
    } = form.elements;

    try {
      validateConditionDataJson(dataTextarea.value);
    } catch (error) {
      setConditionDataError(error.message);
      return;
    }

    createAlertConditionTemplate({
      name: nameInput.value,
      default: defaultCheckbox.checked,
      policyTemplateId: policySelect.value,
      applyToAccounts: applyToAccountsCheckbox.checked,
      data: dataTextarea.value,
      conditionType: ConditionType.INFRASTRUCTURE,
    }).then(returnToAlertConditions);
  };

  const loading = loadingPolicies || loadingCreate;

  return (
    <form className="mb-3 flex flex-col gap-2 px-1" onSubmit={handleSubmit}>
      <PageTitle title="Create Alert Condition" />

      <Fields className="flex flex-col">
        <div className="flex gap-3">
          <Field
            className="flex-1"
            description="This name exists to identify the alert condition in Atlas."
            htmlFor="name"
            label="Name"
          >
            <Input id="name" name="nameInput" required disabled={loading} />
          </Field>

          <Field
            className="flex-1"
            description="The policy that this alert condition will be associated with."
            htmlFor="policy"
            label="Policy"
          >
            <Select
              id="policy"
              name="policySelect"
              required
              disabled={loading}
              options={policyTemplates.map((policy) => (
                <option key={policy.id} value={policy.id}>
                  {policy.name}
                </option>
              ))}
            />
          </Field>
        </div>
        <div className="flex flex-col gap-3">
          <Checkbox
            description="This will cause the alert condition to be automatically selected when setting up new accounts."
            id="default"
            label="Default Alert Condition"
            name="defaultCheckbox"
            disabled={loading}
          />
          <Checkbox
            description="This will create the templatized condition in all active New Relic accounts"
            id="apply-to-accounts"
            label="Apply to Existing Accounts"
            name="applyToAccountsCheckbox"
            disabled={loading}
          />
        </div>
        <div>
          <h3 className="mb-1 text-subdued">
            The following template variables are available for all string
            values:
          </h3>
          <ul className="list-inside list-disc">
            <li>
              <span className="font-mono font-bold">{"${accountId}"}</span> -
              New Relic account ID
            </li>
            <li>
              <span className="font-mono font-bold">{"${customerName}"}</span> -
              The customer's name
            </li>
            <li>
              <span className="font-mono font-bold">{"${appIds}"}</span> - List
              of Application IDs from the account
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <span className="font-mono font-semibold text-action">
                account_id
              </span>{" "}
              fields with a value of{" "}
              <span className="font-mono font-bold">{"${accountId}"}</span> will
              automatically be converted to an integer.
            </li>
            <li>
              <span className="font-mono font-semibold text-action">
                entity_ids
              </span>{" "}
              fields with a value of{" "}
              <span className="font-mono font-bold">{"${appIds}"}</span> will
              automatically be converted to a list of integers.
            </li>
            <li>
              Omit{" "}
              <span className="font-mono font-semibold text-action">
                policy_id
              </span>{" "}
              as it will be overwritten automatically
            </li>
          </ul>
        </div>
        <Field
          error={conditionDataError}
          htmlFor="data"
          label="Data"
          className="col-span-2"
          description={
            <>
              This is the JSON representation of the New Relic alert condition.
              Please visit{" "}
              <Link
                href="https://docs.newrelic.com/docs/alerts-applied-intelligence/new-relic-alerts/rest-api-alerts/alerts-conditions-api-field-names/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                New Relic's documentation
              </Link>{" "}
              for more information.
            </>
          }
        >
          <Textarea
            defaultValue="{}"
            id="data"
            name="dataTextarea"
            onBlur={handleBlurConditionData}
            required
            rows={5}
            disabled={loading}
          />
          <Button
            kind="secondary"
            fill="subdued"
            size="small"
            onClick={handleFormatJsonData}
            className="m-0 max-w-[50px]"
            aria-label="Format JSON"
            title="Format JSON"
          >
            {`{ }`}
          </Button>
        </Field>
      </Fields>

      <Buttons align="right">
        <Button
          kind="primary"
          className="px-4"
          type="submit"
          loading={loading}
          disabled={conditionDataError !== ""}
        >
          Create
        </Button>
        <Button
          kind="primary"
          fill="none"
          className="px-4"
          onClick={handleClickCancel}
        >
          Cancel
        </Button>
      </Buttons>
    </form>
  );
};

interface AlertConditionCreateFormElements extends HTMLFormControlsCollection {
  policySelect: HTMLSelectElement;
  applyToAccountsCheckbox: HTMLInputElement;
  dataTextarea: HTMLTextAreaElement;
  defaultCheckbox: HTMLInputElement;
  nameInput: HTMLInputElement;
}

export interface AlertConditionCreateForm extends HTMLFormElement {
  readonly elements: AlertConditionCreateFormElements;
}
