import clsx from "clsx";
import { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import {
  BooleanParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  Badge,
  CopyButton,
  Dropdown,
  DropdownItem,
  FilterBar,
  FilterSelect,
  FilterToggle,
  Link,
  Search,
  Table,
  TableHeader,
  Tooltip,
  useSearchTerm,
} from "@m/ui";
import { formatFullDate } from "@m/utils";

import { PATHS } from "@atlas/constants";

import { AwsAccount, useAwsAccounts } from "../api";
import { AwsIcon, OktaIcon } from "../icons";
import { AwsAccountAuthMethod, AwsOrgAccountType } from "../types";

import { AwsAccountDetailsModal } from "./AwsAccountDetailsModal";
import { AwsAccountEditModal } from "./AwsAccountEditModal";

export const DEFAULT_SORT = "COMPANY__NAME_ASC";

export const AwsAccountsTable = () => {
  const [query, setQuery] = useQueryParams({
    payersOnly: withDefault(BooleanParam, false),
    search: StringParam,
    sort: withDefault(StringParam, DEFAULT_SORT),
    status: StringParam,
  });

  const [accountToView, setAccountToView] = useState<AwsAccount | null>(null);
  const [accountToEdit, setAccountToEdit] = useState<AwsAccount | null>(null);

  const {
    data: { awsAccounts },
    pagination,
    loading,
  } = useAwsAccounts(query);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ setQuery, query });

  const handleSortChange = (sort: string) => {
    pagination.setCurrentPage(1);
    setQuery({ sort });
  };

  const handleChangeDisabledFilter = (value: string) => {
    setQuery({ status: value });
  };

  const handleClearDisabledFilter = () => {
    setQuery({ status: undefined });
  };

  const handleChangePayersOnly = () => {
    setQuery({ payersOnly: !query.payersOnly });
  };

  const handleResetFilters = () => {
    clearSearchTerm();
    setQuery({
      search: undefined,
      sort: DEFAULT_SORT,
      status: undefined,
      payersOnly: false,
    });
  };

  const handleCloseDetails = () => setAccountToView(null);
  const handleCloseEdit = () => setAccountToEdit(null);

  const rows = useMemo(
    () =>
      awsAccounts.map((awsAccount) => {
        const handleViewDetails = () => setAccountToView(awsAccount);
        const handleEditAccount = () => setAccountToEdit(awsAccount);

        return {
          id: (
            <AccountId
              id={awsAccount.accountId}
              authMethod={awsAccount.authMethod}
              disabled={awsAccount.disabled}
              oktaApp={awsAccount.oktaApp}
              signinUrl={awsAccount.signinUrl}
            />
          ),
          auth: <AuthMethod authMethod={awsAccount.authMethod} />,
          name: (
            <AccountName
              name={awsAccount.name}
              disabled={awsAccount.disabled}
            />
          ),
          company: (
            <CompanyName
              company={awsAccount.company}
              disabled={awsAccount.disabled}
            />
          ),
          org: (
            <div className={clsx({ "opacity-60": awsAccount.disabled })}>
              <AwsOrgAccountTypeBadge
                awsOrgAccountType={awsAccount.awsOrgAccountType}
              />
            </div>
          ),
          added: <div>{formatFullDate(awsAccount.added)}</div>,
          actions: (
            <div className="flex justify-end">
              <Dropdown direction="left">
                <DropdownItem onClick={handleViewDetails}>
                  View Details
                </DropdownItem>
                <DropdownItem onClick={handleEditAccount}>
                  Edit Account
                </DropdownItem>
              </Dropdown>
            </div>
          ),
        };
      }),
    [awsAccounts]
  );

  const isFilterActive =
    query.search !== undefined ||
    query.sort !== DEFAULT_SORT ||
    query.status !== undefined ||
    query.payersOnly === true;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <FilterBar
          isActive={isFilterActive}
          onResetFilters={handleResetFilters}
          className="p-1"
        >
          <Search
            handleSearchInputChange={handleSearchInputChange}
            handleSubmitSearch={handleSubmitSearch}
            placeholder="Search by name, ID, or customer"
            searchTerm={searchTerm}
          />
          <FilterSelect
            ariaLabel="Status Filter"
            disabled={loading}
            initialValue="Active"
            onChange={handleChangeDisabledFilter}
            onClear={handleClearDisabledFilter}
            options={STATUS_FILTER_OPTIONS}
            selection={query.status}
            displayValue={
              STATUS_FILTER_OPTIONS.find((o) => o.id === query.status)?.label
            }
          />
          <FilterToggle
            ariaLabel="Payers Only Filter"
            active={query.payersOnly}
            disabled={loading}
            label="Payers Only"
            onClick={handleChangePayersOnly}
          />
        </FilterBar>
      </div>

      <Table
        defaultSort={query.sort}
        emptyMessage="No AWS accounts found"
        headers={HEADERS}
        loading={loading}
        onSortChange={handleSortChange}
        rows={rows}
        {...pagination}
      />
      {accountToView && (
        <AwsAccountDetailsModal
          awsAccount={accountToView}
          onClose={handleCloseDetails}
        />
      )}
      {accountToEdit && (
        <AwsAccountEditModal
          awsAccount={accountToEdit}
          onClose={handleCloseEdit}
        />
      )}
    </div>
  );
};

const AccountId = ({
  id,
  authMethod,
  disabled,
  oktaApp,
  signinUrl,
}: {
  id: string;
  authMethod: string;
  disabled?: boolean;
  oktaApp?: { embedUrl: string };
  signinUrl?: string;
}) => {
  let accountLink = null;
  if (authMethod === AwsAccountAuthMethod.CrossAccount) {
    accountLink = signinUrl;
  } else if (authMethod === AwsAccountAuthMethod.Legacy) {
    accountLink = oktaApp?.embedUrl;
  }
  return (
    <div className="flex items-center gap-1 font-mono">
      {accountLink ? (
        <Link
          href={accountLink}
          target="_blank"
          rel="noreferrer"
          disabled={disabled}
        >
          {id}
        </Link>
      ) : (
        id
      )}
      <CopyButton text={id} />
    </div>
  );
};

const AuthMethod = ({ authMethod }: { authMethod: string }) => {
  if (authMethod === AwsAccountAuthMethod.None) return "No Access";

  let tooltipContent = null;
  let icon = null;
  let alt = null;

  if (authMethod === AwsAccountAuthMethod.CrossAccount) {
    tooltipContent = "Cross-Account IAM Role";
    icon = AwsIcon;
    alt = "AWS icon";
  } else if (authMethod === AwsAccountAuthMethod.Legacy) {
    tooltipContent = "Okta-based SAML";
    icon = OktaIcon;
    alt = "Okta icon";
  }

  return (
    <Tooltip content={tooltipContent}>
      <img src={icon} alt={alt} className="h-2.5" />
    </Tooltip>
  );
};

const AccountName = ({
  name,
  disabled,
}: {
  name: string;
  disabled?: boolean;
}) => (
  <div className="flex items-center gap-2">
    <div
      className={clsx("text-sm", {
        "text-default": !disabled,
        "text-subdued": disabled,
      })}
    >
      {name}
    </div>
    {disabled && <div className="text-medium text-2xs">DISABLED</div>}
  </div>
);

const CompanyName = ({
  company,
  disabled,
}: {
  company?: { name: string; slug?: string };
  disabled: boolean;
}) => {
  if (!company) return null;

  return (
    <div
      className={clsx("text-sm", {
        "text-default": !disabled,
        "text-subdued": disabled,
      })}
    >
      <Link
        to={generatePath(PATHS.CUSTOMER_DETAILS, { companySlug: company.slug })}
      >
        {company.name}
      </Link>
    </div>
  );
};

const AwsOrgAccountTypeBadge = ({
  awsOrgAccountType,
}: {
  awsOrgAccountType: string;
}) => {
  if (awsOrgAccountType === AwsOrgAccountType.Payer) {
    return <Badge size="small" status="active" label="Payer Account" strong />;
  }
  if (awsOrgAccountType === AwsOrgAccountType.Child) {
    return <Badge size="small" label="Member Account" status="active" />;
  }
  return <Badge size="small" label="Unaffiliated" />;
};

const HEADERS: TableHeader[] = [
  {
    accessor: "id",
    label: "ID",
    sort: "ID",
  },
  {
    accessor: "auth",
    label: "Auth Method",
  },
  {
    accessor: "name",
    label: "Account Name",
    sort: "NAME",
  },
  {
    accessor: "company",
    label: "Company",
    sort: "COMPANY__NAME",
  },
  {
    accessor: "org",
    label: "AWS Org.", // todo: add sort by aws org account type
  },
  {
    accessor: "added",
    label: "Date Added",
    sort: "ADDED",
  },
  {
    accessor: "actions",
    label: " ",
  },
];

const STATUS_FILTER_OPTIONS = [
  { id: "active", label: "Active" },
  { id: "disabled", label: "Disabled" },
];
