import { useMutation, useQueryClient } from "@tanstack/react-query";

import { toast } from "@m/ui";

import { ACCESS_TOKEN_URL } from "../constants";

import { PERSONAL_ACCESS_TOKENS_QUERY_KEY } from "./usePersonalAccessTokens";

export const useCreatePersonalAccessToken = (
  onTokenCreated: (token: string, expiresAt: string) => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPersonalAccessToken,
    onSuccess: (data) => {
      queryClient.refetchQueries({
        queryKey: [PERSONAL_ACCESS_TOKENS_QUERY_KEY],
      });
      toast.success("Personal access token created successfully");
      onTokenCreated(data.token, data.expires_at);
    },
    onError: (error) => toast.error(error.message),
  });
};

const createPersonalAccessToken = async ({
  name,
  expirationDate,
}: {
  name: string;
  expirationDate?: string;
}) => {
  const response = await fetch(ACCESS_TOKEN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": localStorage.getItem("csrf"),
    },
    credentials: "include",
    body: JSON.stringify({ name, expires_at: expirationDate }),
  });

  const body = await response.json();

  if (!response.ok) throw new Error(body.message);

  return body;
};
