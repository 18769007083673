import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";

export const GET_NEW_RELIC_ALERT_CONDITION_TEMPLATE = gql(/* GraphQL */ `
  query GetNewRelicAlertConditionTemplate($id: ID!) {
    newRelicConditionTemplate(id: $id) {
      id
      data
      name
      conditionType
      default
      policyTemplate {
        id
        name
      }
    }
  }
`);

export const useNewRelicAlertConditionTemplate = (id: string) => {
  const { data, ...result } = useQuery(GET_NEW_RELIC_ALERT_CONDITION_TEMPLATE, {
    variables: { id },
  });

  const alertConditionTemplate = data?.newRelicConditionTemplate ?? null;

  return { data: { alertConditionTemplate }, ...result };
};
