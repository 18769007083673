import { GraphQLError } from "graphql";
import { WildcardMockedResponse } from "wildcard-mock-link";

import { GQL_DELAY, MAX_TIMEOUT, UNLIMITED } from "./constants";

export const generateMockId = (length = 12, includeLetters = false) => {
  let characters = "0123456789";
  if (includeLetters) {
    characters += "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  }

  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};

export const generateRandomDate = (start?: Date, end?: Date) => {
  const startDate = start || new Date(2020, 0, 1); // Default to Jan 1, 2020
  const endDate = end || new Date(); // Default to current date

  const startTime = startDate.getTime();
  const endTime = endDate.getTime();
  const randomTime = startTime + Math.random() * (endTime - startTime);

  return new Date(randomTime).toISOString();
};

export const successState = (
  mock: WildcardMockedResponse,
  options?: { delay?: number; nMatches?: number }
): WildcardMockedResponse => ({
  ...mock,
  delay: options?.delay || GQL_DELAY,
  nMatches: options?.nMatches || UNLIMITED,
});

export const loadingState = (
  mock: WildcardMockedResponse
): WildcardMockedResponse => ({
  ...mock,
  delay: MAX_TIMEOUT,
  nMatches: UNLIMITED,
});

export const errorState = (
  mock: WildcardMockedResponse
): WildcardMockedResponse => ({
  ...mock,
  delay: GQL_DELAY,
  nMatches: UNLIMITED,
  result: {
    errors: [new GraphQLError("Error!")],
  },
});
