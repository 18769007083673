import { useQuery } from "@m/api";
import { gql } from "@m/api/v4";
import { CustomerBasicDetailsQuery } from "@m/api/v4/types";

export const GET_CUSTOMER_BASIC_DETAILS = gql(/* GraphQL */ `
  query CustomerBasicDetails($companySlug: String!) {
    company(slug: $companySlug) {
      id
      slug
      mcn
      name
      status
    }
  }
`);

export type CustomerBasicDetails = CustomerBasicDetailsQuery["company"];

export const useCustomerBasicDetails = (companySlug: string) => {
  const { data, loading, error } = useQuery(GET_CUSTOMER_BASIC_DETAILS, {
    variables: { companySlug },
    fetchPolicy: "cache-first",
  });

  return {
    data: data?.company,
    loading,
    error,
  };
};
