import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";

import { Button, Card, CopyButton, Field, Input, Link, Tooltip } from "@m/ui";
import { formatFullDate } from "@m/utils";

interface PersonalAccessTokenProps {
  token: string;
  expiresAt?: string;
}

export const PersonalAccessToken = ({
  token,
  expiresAt,
}: PersonalAccessTokenProps) => {
  const [showToken, setShowToken] = useState(false);

  return (
    <Card className="flex flex-col gap-3 p-2.5">
      <Field
        label="Your personal access token"
        description="Make sure you save it - you won't be able to access it again."
        htmlFor="new-personal-access-token"
      >
        <Input
          id="new-personal-access-token"
          value={showToken ? token : "•".repeat(token.length)}
          className={clsx({ "tracking-widest": !showToken })}
          readOnly
          rightAddon={
            <>
              <Tooltip
                content={showToken ? "Click to hide" : "Click to reveal"}
              >
                <Button
                  as="div"
                  aria-label={showToken ? "Hide token" : "Reveal token"}
                  fill="none"
                  kind="primary"
                  leftIcon={showToken ? EyeSlashIcon : EyeIcon}
                  onClick={() => setShowToken(!showToken)}
                  size="small"
                />
              </Tooltip>
              <CopyButton text={token} />
            </>
          }
          container={{ className: "max-w-lg" }}
        />
        {expiresAt && (
          <div className="mt-0.5 text-xs font-medium text-subdued">
            Expires on {formatFullDate(expiresAt, "LLLL d, yyyy")}
          </div>
        )}
      </Field>

      <div className="text-sm font-medium text-subdued">
        Need help installing the missionctl command line tool? Learn more{" "}
        <Link
          href="https://missioncloudinc.atlassian.net/wiki/x/MgBzBQ?atlOrigin=eyJpIjoiYmQ2ODQ2YmIzYzA0NDE3NmFmZWQyMmNjOWQ1NWI2MmUiLCJwIjoiYyJ9"
          target="_blank"
          rel="noopener noreferrer"
          rightIcon={ArrowTopRightOnSquareIcon}
        >
          here
        </Link>
      </div>
    </Card>
  );
};
