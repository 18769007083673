import { FetchResult } from "@apollo/client";
import { FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  CreateDomainMonitorMutation,
  StatusCodePreference,
} from "@m/api/v4/types";
import {
  Button,
  Buttons,
  Checkbox,
  DynamicSelect,
  Field,
  Fields,
  Input,
  toast,
} from "@m/ui";
import { fromSnakeCaseToProperCase } from "@m/utils";

import { PageTitle } from "@atlas/components";

import { useCreateNewRelicAccountDomainMonitor } from "../api";

export const NewRelicAccountDomainMonitorCreatePage = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const navigate = useNavigate();

  const { createDomainMonitor, loading: loadingMutation } =
    useCreateNewRelicAccountDomainMonitor();

  const returnToDomainMonitors = () => {
    navigate("../");
  };

  const handleSubmit = (
    e: FormEvent<NewRelicAccountDomainMonitorCreateForm>
  ) => {
    e.preventDefault();
    const form = e.currentTarget;

    const {
      nameInput,
      domainInput,
      expireThresholdInput,
      statusCodePreferenceInput,
      enableMonitorCheckbox,
    } = form.elements as NewRelicAccountDomainMonitorCreateFormElements;

    createDomainMonitor({
      accountId,
      domain: domainInput.value,
      expireThreshold: parseInt(expireThresholdInput.value),
      statusCodeAlertPreference: statusCodePreferenceInput.value,
      enabled: enableMonitorCheckbox.checked,
      name: nameInput.value,
    })
      .then((fetchResult: FetchResult<CreateDomainMonitorMutation>) => {
        const { ok, message } =
          fetchResult.data?.createNewRelicDomainMonitor || {};
        if (ok) {
          toast.success("Domain monitor created successfully.");
          returnToDomainMonitors();
        } else {
          toast.error(message || "Failed to create domain monitor.");
        }
      })
      .catch(() => {
        toast.error("There was a problem. Please try again later.");
      });
  };

  const handleCancel = () => {
    navigate("../");
  };

  return (
    <form
      className="flex flex-col gap-3 px-1"
      onSubmit={handleSubmit}
      role="form"
    >
      <PageTitle title="Create Domain Monitor" />
      <Fields className="grid grid-cols-2 items-end gap-x-3">
        <Field className="col-span-1" htmlFor="name" label="Name">
          <Input
            id="name"
            name="nameInput"
            placeholder="This name identifies the monitor"
            autoComplete="off"
            autoCorrect="off"
            required={true}
          />
        </Field>
        <Field className="col-span-1" htmlFor="domain" label="Domain">
          <Input
            id="domain"
            name="domainInput"
            placeholder="The specific domain name to monitor"
            autoComplete="off"
            autoCorrect="off"
            required={true}
          />
        </Field>
        <Field
          className="col-span-1"
          description="Number of days until an expiration notification is created"
          htmlFor="expire-threshold"
          label="Expire Threshold"
        >
          <Input
            id="expire-threshold"
            min={1}
            name="expireThresholdInput"
            placeholder="e.g. 30"
            type="number"
            required={true}
          />
        </Field>
        <Field
          className="col-span-1"
          description="This setting filters status codes from opening incidents."
          htmlFor="status-code-preference"
          label="Status Code Alert Preference"
        >
          <DynamicSelect
            id="status-code-preference"
            name="statusCodePreferenceInput"
            initialOptions={Object.values(StatusCodePreference).map(
              (value) => ({ value, label: fromSnakeCaseToProperCase(value) })
            )}
            required
          />
        </Field>
        <Checkbox
          description="Whether or not the monitor should be enabled"
          id="enable-monitor"
          label="Enable Monitor"
          name="enableMonitorCheckbox"
        />
      </Fields>
      <Buttons align="right">
        <Button kind="primary" type="submit" disabled={loadingMutation}>
          Create Domain
        </Button>
        <Button kind="primary" type="button" onClick={handleCancel} fill="none">
          Cancel
        </Button>
      </Buttons>
    </form>
  );
};

interface NewRelicAccountDomainMonitorCreateFormElements
  extends HTMLFormControlsCollection {
  enableMonitorCheckbox: HTMLInputElement;
  nameInput: HTMLInputElement;
  domainInput: HTMLInputElement;
  expireThresholdInput: HTMLInputElement;
  statusCodePreferenceInput: HTMLSelectElement;
}

export interface NewRelicAccountDomainMonitorCreateForm
  extends HTMLFormElement {
  readonly elements: NewRelicAccountDomainMonitorCreateFormElements;
}
