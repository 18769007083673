import {
  EllipsisVerticalIcon,
  NoSymbolIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

import { AccessRequired } from "@m/api/components";
import { Dropdown, DropdownGroup, DropdownItem } from "@m/ui";

import { ATLAS_MUTATIONS } from "@atlas/constants";

interface MeteredChargesTableActionsProps {
  canAddMeteredCharge: boolean;
  canStopMetering: boolean;
  disabled: boolean;
  onAddMeteredChargeClick: () => void;
  onStopMeteringClick: () => void;
}

export const MeteredChargesTableActions = ({
  canAddMeteredCharge,
  canStopMetering,
  disabled,
  onAddMeteredChargeClick,
  onStopMeteringClick,
}: MeteredChargesTableActionsProps) => {
  return (
    <div className="py-0.5">
      <Dropdown
        className="w-fit p-1 py-0.5"
        direction="bottom-start"
        trigger={
          <EllipsisVerticalIcon
            aria-label="Open Subscription Actions"
            className="h-2.5 w-2.5"
          />
        }
      >
        <DropdownGroup aria-label="Subscription Actions">
          <DropdownItem
            disabled={!canAddMeteredCharge || disabled}
            icon={PlusIcon}
            onClick={onAddMeteredChargeClick}
          >
            Add Metered Charge
          </DropdownItem>
        </DropdownGroup>
        <AccessRequired mutation={ATLAS_MUTATIONS.DISABLE_MARKETPLACE_METERING}>
          <DropdownGroup>
            <DropdownItem
              disabled={!canStopMetering || disabled}
              icon={NoSymbolIcon}
              onClick={onStopMeteringClick}
            >
              Stop Metering
            </DropdownItem>
          </DropdownGroup>
        </AccessRequired>
      </Dropdown>
    </div>
  );
};
