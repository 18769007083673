import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";

export const DELETE_NEW_RELIC_POLICY = gql(/* GraphQL */ `
  mutation DeleteNewRelicPolicy($id: ID!) {
    deleteNewRelicPolicy(id: $id) {
      ok
      message
    }
  }
`);

export const useDeleteNewRelicPolicy = () => {
  const [mutate, result] = useMutation(DELETE_NEW_RELIC_POLICY);

  const deleteNewRelicPolicy = (policyId: string) => {
    return mutate({ variables: { id: policyId } });
  };

  return [deleteNewRelicPolicy, result] as const;
};
