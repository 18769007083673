import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

import { GET_NEW_RELIC_DASHBOARD_TEMPLATES } from "./useNewRelicDashboardTemplates";

export const DELETE_NEW_RELIC_DASHBOARD_TEMPLATE = gql(/* GraphQL */ `
  mutation DeleteNewRelicDashboardTemplate($id: ID!) {
    deleteNewRelicDashboardTemplate(id: $id) {
      message
      ok
    }
  }
`);

export const useDeleteNewRelicDashboardTemplate = () => {
  const [mutate, result] = useMutation(DELETE_NEW_RELIC_DASHBOARD_TEMPLATE, {
    refetchQueries: [GET_NEW_RELIC_DASHBOARD_TEMPLATES],
    onError: () => toast.error("There was a problem. Please try again later."),
    onCompleted: (data) => {
      if (data?.deleteNewRelicDashboardTemplate?.ok) {
        toast.success("Dashboard template deleted successfully.");
      } else {
        toast.error(data.deleteNewRelicDashboardTemplate?.message);
      }
    },
  });

  const deleteNewRelicDashboardTemplate = (id: string) => {
    return mutate({ variables: { id } });
  };

  return [deleteNewRelicDashboardTemplate, result] as const;
};
