import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  NewRelicDashboardTemplateFilter,
  NewRelicDashboardTemplateSortEnum,
} from "@m/api/v4/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

export const GET_NEW_RELIC_DASHBOARD_TEMPLATES = gql(/* GraphQL */ `
  query GetNewRelicDashboardTemplates(
    $sort: [NewRelicDashboardTemplateSortEnum]
    $where: NewRelicDashboardTemplateFilter
  ) {
    newRelicDashboardTemplates(sort: $sort, where: $where) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          version
          default
          lastUpdatedAt
          lastUpdatedBy {
            email
            firstName
            lastName
            name
          }
        }
      }
    }
  }
`);

interface NewRelicDashboardTemplatesQueryParams {
  sort: NewRelicDashboardTemplateSortEnum;
  search?: string;
}

export const useNewRelicDashboardTemplates = (
  queryParams: NewRelicDashboardTemplatesQueryParams
) => {
  const where: NewRelicDashboardTemplateFilter = {};

  if (queryParams.search) {
    where.or = [{ nameIlike: `%${queryParams.search}%` }];
  }

  const { data, fetchMore, ...result } = useQuery(
    GET_NEW_RELIC_DASHBOARD_TEMPLATES,
    {
      variables: {
        ...INITIAL_PAGINATION,
        sort: queryParams.sort,
        where,
      },
      notifyOnNetworkStatusChange: true,
      onError: () => toast.error("Unable to fetch dashboard templates"),
    }
  );

  const {
    edges = [],
    pageInfo = {},
    totalCount = 0,
  } = data?.newRelicDashboardTemplates ?? {};

  const dashboardTemplates = edges.map((edge) => edge.node) ?? [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return { data: { dashboardTemplates }, pagination, ...result };
};
