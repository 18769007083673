import { ArrowPathRoundedSquareIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

import {
  Badge,
  Dropdown,
  DropdownItem,
  StopPropagation,
  Table,
  TableHeader,
} from "@m/ui";
import { formatDate, fromSnakeCaseToProperCase, toProperCase } from "@m/utils";

import { useCustomerNewRelicAccounts, useSyncNewRelicAccount } from "../api";

interface NewRelicAccountsTableProps {
  companySlug: string;
}

export const NewRelicAccountsTable = ({
  companySlug,
}: NewRelicAccountsTableProps) => {
  const navigate = useNavigate();

  const {
    data: { newRelicAccounts },
    loading,
  } = useCustomerNewRelicAccounts(companySlug);

  const rows = newRelicAccounts.map((account) => ({
    name: <AccountName name={account.name} />,
    lastSync: <AccountLastSync lastSynced={account.lastSynced} />,
    status: <AccountStatus status={account.status} />,
    syncState: <AccountSyncState syncState={account.syncState} />,
    actions: (
      <StopPropagation>
        <AccountActions
          accountId={account.accountId.toString()}
          syncState={account.syncState}
        />
      </StopPropagation>
    ),
    onClick: () => navigate(`./${account.accountId}`),
  }));

  return (
    <div>
      <Table
        emptyMessage="No accounts configured"
        headers={NEW_RELIC_ACCOUNTS_TABLE_HEADERS}
        loading={loading}
        rows={rows}
      />
    </div>
  );
};

const AccountName = ({ name }: { name: string }) => (
  <div className="text-sm font-medium text-default">{name}</div>
);

const AccountLastSync = ({ lastSynced }: { lastSynced: string }) => (
  <div className="text-sm font-medium text-default">
    {lastSynced ? formatDate(lastSynced, { longFormat: true }) : "Never"}
  </div>
);

const AccountStatus = ({ status }: { status: string }) => (
  <Badge
    label={toProperCase(status)}
    status={status.toLowerCase() === "active" ? "positive" : "warning"}
  />
);

const AccountSyncState = ({ syncState }: { syncState: string }) => (
  <div className="text-sm font-medium text-default">
    {fromSnakeCaseToProperCase(syncState)}
  </div>
);

const AccountActions = ({
  accountId,
  syncState,
}: {
  accountId: string;
  syncState: string;
}) => {
  const [syncNewRelicAccount, { loading: syncNewRelicAccountLoading }] =
    useSyncNewRelicAccount(accountId);

  if (syncState === "PENDING_EMAIL_VERIFICATION") return null;

  return (
    <Dropdown>
      <DropdownItem
        className="!bg-form-active !bg-opacity-[.07] !text-action hover:!bg-opacity-[.12]"
        onClick={syncNewRelicAccount}
        disabled={syncNewRelicAccountLoading}
      >
        <span className="grow text-left">Sync</span>
        <ArrowPathRoundedSquareIcon className="h-2.5 w-2.5" />
      </DropdownItem>
      <DropdownItem to={`./${accountId}/domain-monitors`}>
        Domain Monitors
      </DropdownItem>
    </Dropdown>
  );
};

const NEW_RELIC_ACCOUNTS_TABLE_HEADERS: TableHeader[] = [
  { label: "Name", accessor: "name", width: "100%" },
  { label: "Last Sync", accessor: "lastSync" },
  { label: "Status", accessor: "status" },
  { label: "Sync State", accessor: "syncState" },
  { label: " ", accessor: "actions" },
];
