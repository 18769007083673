import { useMutation, useQueryClient } from "@tanstack/react-query";

import { toast } from "@m/ui";

import { REVOKE_ACCESS_TOKEN_URL } from "../constants";

import { PERSONAL_ACCESS_TOKENS_QUERY_KEY } from "./usePersonalAccessTokens";

export const useRevokePersonalAccessToken = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: revokePersonalAccessToken,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [PERSONAL_ACCESS_TOKENS_QUERY_KEY],
      });
      toast.success("Personal access token revoked");
    },
    onError: (error) => toast.error(error.message),
  });
};

const revokePersonalAccessToken = async (tokenId: number) => {
  const response = await fetch(
    REVOKE_ACCESS_TOKEN_URL.replace(":tokenId", tokenId.toString()),
    {
      method: "PUT",
      headers: {
        "X-CSRF-Token": localStorage.getItem("csrf"),
      },
      credentials: "include",
    }
  );

  const body = await response.json();

  if (!response.ok) throw new Error(body.message);

  return body;
};
