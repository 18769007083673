import { PATHS } from "@atlas/constants";
import {
  CustomerNewRelicAccountsPage,
  NewRelicAccountDetailsPage,
  NewRelicAccountDomainMonitorCreatePage,
  NewRelicAccountDomainMonitorsPage,
  NewRelicDomainMonitorDetailsPage,
} from "@atlas/features/NewRelic/pages";

export const newRelicRoutes = [
  {
    index: true,
    handle: {
      crumb: () => <span>Accounts</span>,
      to: PATHS.CUSTOMER_NEW_RELIC_ACCOUNTS,
    },
    element: <CustomerNewRelicAccountsPage />,
  },
  {
    path: ":accountId",
    handle: {
      crumb: () => <NewRelicAccountDetailsPage.Crumb />,
      to: PATHS.CUSTOMER_NEW_RELIC_ACCOUNT_DETAILS,
    },
    children: [
      {
        index: true,
        element: <NewRelicAccountDetailsPage />,
      },
      {
        path: "domain-monitors",
        handle: {
          crumb: () => <span>Domain Monitors</span>,
          to: PATHS.CUSTOMER_NEW_RELIC_ACCOUNT_DOMAIN_MONITORS,
        },
        children: [
          {
            index: true,
            element: <NewRelicAccountDomainMonitorsPage />,
          },
          {
            path: ":domainMonitorId",
            handle: {
              crumb: () => <NewRelicDomainMonitorDetailsPage.Crumb />,
              to: PATHS.CUSTOMER_NEW_RELIC_ACCOUNT_DOMAIN_MONITOR_DETAILS,
            },
            element: <NewRelicDomainMonitorDetailsPage />,
          },
          {
            path: "create",
            element: <NewRelicAccountDomainMonitorCreatePage />,
            handle: {
              crumb: () => <span>Create</span>,
            },
          },
        ],
      },
    ],
  },
];
