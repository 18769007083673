import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";

export const CREATE_NEW_RELIC_POLICY = gql(/* GraphQL */ `
  mutation CreateNewRelicPolicy($input: CreateNewRelicPolicyInput!) {
    createNewRelicPolicy(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateNewRelicPolicy = (accountId: string) => {
  const [mutate, result] = useMutation(CREATE_NEW_RELIC_POLICY);

  const createNewRelicPolicy = (templateId: string) => {
    return mutate({ variables: { input: { accountId, templateId } } });
  };

  return [createNewRelicPolicy, result] as const;
};
