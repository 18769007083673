import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";
import { toast } from "@m/ui";

export const UPDATE_NEW_RELIC_ACCOUNT = gql(/* GraphQL */ `
  mutation UpdateNewRelicAccount($input: UpdateNewRelicAccountInput!) {
    updateNewRelicAccount(input: $input) {
      ok
      message
    }
  }
`);

export const useUpdateNewRelicAccount = (accountId: string) => {
  const [mutate, result] = useMutation(UPDATE_NEW_RELIC_ACCOUNT, {
    onError: () => toast.error("Unable to update New Relic account."),
    onCompleted: (data) => {
      if (data.updateNewRelicAccount.ok) {
        toast.success(data.updateNewRelicAccount.message);
      } else {
        toast.error(data.updateNewRelicAccount.message);
      }
    },
  });

  const updateNewRelicAccount = (autoSync: boolean) => {
    return mutate({ variables: { input: { accountId, autoSync } } });
  };

  return [updateNewRelicAccount, result] as const;
};
