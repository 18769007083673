import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import { GetNewRelicPolicyTemplatesQuery } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const GET_NEW_RELIC_POLICY_TEMPLATES = gql(/* GraphQL */ `
  query GetNewRelicPolicyTemplates {
    newRelicPolicyTemplates {
      edges {
        node {
          id
          name
          default
        }
      }
    }
  }
`);

export const useNewRelicPolicyTemplates = () => {
  const onError = () =>
    toast.error(
      "There was a problem loading policy templates. Please try again later."
    );

  const { data, loading, error } = useQuery(GET_NEW_RELIC_POLICY_TEMPLATES, {
    onError,
  });
  const { edges = [] } = data?.newRelicPolicyTemplates || {};

  const policyTemplates = edges.map(({ node }) => node) ?? [];

  return { data: { policyTemplates }, loading, error };
};

export type NewRelicPolicyTemplate =
  GetNewRelicPolicyTemplatesQuery["newRelicPolicyTemplates"]["edges"][number]["node"];
