import { Button } from "@m/ui";

import { PageTitle } from "@atlas/components";

import "../api";
import { NewRelicAlertConditionTemplatesTable } from "../components";

export const NewRelicAlertConditionTemplatesPage = () => {
  return (
    <main className="flex flex-col gap-3">
      <PageTitle
        title="Alert Conditions"
        description={
          <div className="text-sm font-normal text-subdued">
            Manage alert condition templates.
          </div>
        }
        actions={
          <Button kind="primary" size="small" to="./create">
            Create
          </Button>
        }
      />
      <NewRelicAlertConditionTemplatesTable />
    </main>
  );
};
