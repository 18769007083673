import { useMemo, useState } from "react";

import { Button, Buttons, Confirm, Table, TableHeader, Timestamp } from "@m/ui";

import { usePersonalAccessTokens, useRevokePersonalAccessToken } from "../api";
import { PersonalAccessToken } from "../types";

export const PersonalAccessTokensTable = () => {
  const { data: tokens = [], isLoading } = usePersonalAccessTokens();
  const { mutate: revokeToken, isPending } = useRevokePersonalAccessToken();

  const [revokeTokenId, setRevokeTokenId] = useState<number | null>(null);

  const handleCloseRevokeModal = () => setRevokeTokenId(null);

  const handleRevokeToken = () => {
    revokeToken(revokeTokenId, { onSuccess: handleCloseRevokeModal });
  };

  const rows = useMemo(() => {
    return tokens.map((token: PersonalAccessToken) => {
      const handleOpenRevokeModal = () => setRevokeTokenId(token.id);

      return {
        name: <TokenName name={token.name} />,
        created: <TokenTimestamp isoTime={token.createdAt} />,
        expires: <TokenTimestamp isoTime={token.expiresAt} />,
        lastActive: <TokenTimestamp isoTime={token.lastActive} />,
        revoke: (
          <Button
            kind="destructive"
            fill="none"
            size="small"
            onClick={handleOpenRevokeModal}
          >
            Revoke
          </Button>
        ),
      };
    });
  }, [tokens]);

  return (
    <>
      <Table
        rows={rows}
        headers={ACCESS_TOKENS_TABLE_HEADERS}
        loading={isLoading}
      />

      <Confirm
        actions={
          <Buttons>
            <Button kind="primary" fill="none" onClick={handleCloseRevokeModal}>
              Cancel
            </Button>
            <Button
              kind="primary"
              onClick={handleRevokeToken}
              loading={isPending}
            >
              Revoke token
            </Button>
          </Buttons>
        }
        onClose={handleCloseRevokeModal}
        open={!!revokeTokenId}
      >
        Are you sure you want to revoke this token?
        <br />
        <br />
        <strong>This action cannot be undone.</strong>
      </Confirm>
    </>
  );
};

const TokenName = ({ name }: { name: string }) => {
  return <div className="text-sm font-semibold text-default">{name}</div>;
};

const TokenTimestamp = ({ isoTime }: { isoTime: string }) => {
  return isoTime ? (
    <Timestamp isoTime={isoTime} longFormat className="text-sm text-default" />
  ) : (
    <span className="text-sm text-default">Never</span>
  );
};

const ACCESS_TOKENS_TABLE_HEADERS: TableHeader[] = [
  {
    label: "Name",
    accessor: "name",
  },
  {
    label: "Created",
    accessor: "created",
    width: "20%",
  },
  {
    label: "Expires",
    accessor: "expires",
    width: "20%",
  },
  {
    label: "Last Active",
    accessor: "lastActive",
    width: "20%",
  },
  {
    label: " ",
    accessor: "revoke",
    width: "10%",
    align: "right",
  },
];
