import { createContext, createRef, useContext } from "react";

import { UserSession } from "@m/types";

interface AuthContextValue {
  isAuthenticated: boolean;
  logout: () => void;
  updateUserSession: (user: UserSession) => void;
  user: UserSession | null;
}

export const AuthContext = createContext<AuthContextValue>(undefined);

export const AuthContextRef = createRef();

export const useAuth = () => useContext(AuthContext);
