import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";

export const CREATE_NEW_RELIC_DASHBOARD = gql(/* GraphQL */ `
  mutation CreateNewRelicDashboard($input: CreateNewRelicDashboardInput!) {
    createNewRelicDashboard(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateNewRelicDashboard = (accountId: string) => {
  const [mutate, result] = useMutation(CREATE_NEW_RELIC_DASHBOARD);

  const createNewRelicDashboard = (templateId: string) => {
    return mutate({ variables: { input: { accountId, templateId } } });
  };

  return [createNewRelicDashboard, result] as const;
};
