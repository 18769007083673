import { Duration, DateTime as dt } from "luxon";

export const formatDate = (isoDate, options = { longFormat: false }) => {
  if (typeof isoDate === "number") {
    // parse a unix epoch
    isoDate = dt.fromSeconds(isoDate);
  }
  const date = dt.fromISO(isoDate).toLocal();
  const currentDate = dt.now();
  const diff = currentDate - date; // the difference in milliseconds

  // Handle future dates
  if (diff < 0) {
    const absDiff = Math.abs(diff);
    const minutes = Math.floor(absDiff / 60000);

    if (minutes < 5) {
      return "in a few minutes";
    }

    if (minutes < 60) {
      return date
        .toRelative()
        .replace(/ minutes?/, options.longFormat ? " minutes" : "m");
    }

    const hours = Math.floor(absDiff / 3600000);
    const hoursVerbiage = hours === 1 ? " hour" : " hours";
    if (hours < 24) {
      return date
        .toRelative()
        .replace(/ hours?/, options.longFormat ? hoursVerbiage : "h");
    }

    const days = Math.floor(hours / 24);
    if (days < 7) {
      return date
        .toRelative()
        .replace(/ days?/, options.longFormat ? " days" : "d");
    }

    if (days < 28) {
      return date
        .toRelative({ unit: "weeks" })
        .replace(/ weeks?/, options.longFormat ? " weeks" : "w");
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
      return date
        .toRelative({ unit: "months" })
        .replace(/ months?/, options.longFormat ? " months" : "mo");
    }

    const years = Math.floor(days / 365);
    if (years === 1) {
      return options.longFormat ? "in 1 year" : "in 1y";
    }
    return date
      .toRelative({ unit: "years" })
      .replace(/ years?/, options.longFormat ? " years" : "y");
  }

  // Handle past dates
  const minutes = Math.floor(diff / 60000);

  if (minutes < 5) {
    return "just now";
  }

  if (minutes < 60) {
    return date
      .toRelative({ unit: "minutes" })
      .replace(/ minutes?/, options.longFormat ? " minutes" : "m");
  }

  const hours = Math.floor(diff / 3600000);
  const hoursVerbiage = hours === 1 ? " hour" : " hours";
  if (hours < 24) {
    return date
      .toRelative()
      .replace(/ hours?/, options.longFormat ? hoursVerbiage : "h");
  }

  const days = Math.floor(hours / 24);
  if (days === 1 && options.longFormat) {
    return "yesterday";
  }
  if (days < 7) {
    return date
      .toRelative()
      .replace(/ days?/, options.longFormat ? " days" : "d");
  }

  if (days < 14 && options.longFormat) {
    return "last week";
  }
  if (days < 28) {
    return date
      .toRelative({ unit: "weeks" })
      .replace(/ weeks?/, options.longFormat ? " weeks" : "w");
  }

  if (date.year === currentDate.year) {
    return date.toFormat(options.longFormat ? "LLLL dd" : "LLL d");
  }

  return date.toFormat(options.longFormat ? "LLLL dd, yyyy" : "LLL d, yyyy");
};

export const formatFullDate = (isoDate, format = "LLL d, yyyy") => {
  if (!isoDate) return "";
  if (typeof isoDate === "number") {
    // parse a unix epoch
    isoDate = dt.fromSeconds(isoDate);
  }
  return dt.fromISO(isoDate).toFormat(format);
};

export const toTimeLocaleSimple = (isoDate, format = dt.TIME_SIMPLE) => {
  return dt.fromISO(isoDate).toLocaleString(format);
};

export const toTimeLocaleLong = (isoDate) => {
  return `${dt.fromISO(isoDate).toLocaleString({
    month: "short",
    day: "numeric",
    year: "numeric",
  })} at ${dt
    .fromISO(isoDate)
    .toLocaleString({
      hour: "numeric",
      minute: "numeric",
    })
    .toLowerCase()}`;
};

export const getMilliseconds = (isoDate) => {
  return dt.fromISO(isoDate).toMillis();
};

export const localizedDateTime = (
  isoDate,
  showSeconds = false,
  showGMTOffset = false
) => {
  const ss = showSeconds ? ":ss" : "";
  const gmt = showGMTOffset ? " ('GMT' Z)" : "";
  const dateFormatStr = `LLL d, yyyy - HH:mm${ss} ZZZZ${gmt}`;
  return dt.fromISO(isoDate).toFormat(dateFormatStr);
};

export const getDurationText = (startDate, endDate) => {
  const endTime = dt.fromISO(endDate);
  const startTime = dt.fromISO(startDate);

  const { milliseconds } = endTime.diff(startTime).toObject();

  const durationObj = Duration.fromMillis(milliseconds)
    .shiftTo("day", "hour", "minute")
    .toObject();

  const durationString = [];

  if (durationObj.days) {
    durationString.push(`${Math.round(durationObj.days)}d`);
  }
  if (durationObj.hours) {
    durationString.push(`${Math.round(durationObj.hours)}h`);
  }
  if (durationObj.minutes) {
    durationString.push(`${Math.round(durationObj.minutes)}m`);
  }

  return durationString.join(" ");
};

export { dt };
