import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  GetNewRelicAccountDomainMonitorsQuery,
  NewRelicDomainMonitorSortEnum,
} from "@m/api/v4/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

export const GET_NEW_RELIC_ACCOUNT_DOMAIN_MONITORS = gql(/* GraphQL */ `
  query GetNewRelicAccountDomainMonitors(
    $accountId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [NewRelicDomainMonitorSortEnum]
  ) {
    newRelicDomainMonitors(
      accountId: $accountId
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          domain
          lastSynced
          enabled
          expires
          activeIncident {
            title
          }
        }
      }
    }
  }
`);

interface NewRelicAccountDomainMonitorsQueryParams {
  sort: NewRelicDomainMonitorSortEnum;
}

export const useNewRelicAccountDomainMonitors = (
  accountId: string,
  queryParams: NewRelicAccountDomainMonitorsQueryParams
) => {
  const { data, fetchMore, ...result } = useQuery(
    GET_NEW_RELIC_ACCOUNT_DOMAIN_MONITORS,
    {
      variables: {
        accountId: parseInt(accountId),
        sort: queryParams.sort,
        ...INITIAL_PAGINATION,
      },
      onError: () => toast.error("Unable to fetch domain monitors."),
    }
  );

  const {
    edges = [],
    pageInfo = {},
    totalCount = 0,
  } = data?.newRelicDomainMonitors ?? {};

  const domainMonitors = edges.map((edge) => edge.node) ?? [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return { data: { domainMonitors }, pagination, ...result };
};

export type NewRelicDomainMonitorListItem =
  GetNewRelicAccountDomainMonitorsQuery["newRelicDomainMonitors"]["edges"][number]["node"];
