import { PencilIcon } from "@heroicons/react/20/solid";
import { useCallback, useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { NewRelicConditionTemplateSortEnum } from "@m/api/v4/types";
import {
  Avatar,
  FilterBar,
  Search,
  Table,
  TableHeader,
  Timestamp,
  Tooltip,
  useSearchTerm,
} from "@m/ui";

import { PATHS } from "@atlas/constants";

import { useNewRelicAlertConditionTemplates } from "../api";

export const NewRelicAlertConditionTemplatesTable = () => {
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useQueryParams(
    alertConditionTemplatesQueryParams
  );
  const {
    data: { newRelicAlertConditionTemplates },
    pagination,
    loading,
  } = useNewRelicAlertConditionTemplates({
    sort: queryParams.sort,
    search: queryParams.search || undefined,
  });

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ query: queryParams, setQuery: setQueryParams });

  const handleSortChange = (sort: NewRelicConditionTemplateSortEnum) => {
    setQueryParams({ sort });
  };

  const handleResetFilters = () => {
    clearSearchTerm();
    setQueryParams({
      search: undefined,
      sort: NewRelicConditionTemplateSortEnum.NameAsc,
    });
  };

  const handleClickEdit = useCallback(
    (templateId: string) => {
      navigate(
        generatePath(PATHS.SETTINGS_ALERT_CONDITION_TEMPLATE_EDIT, {
          templateId,
        })
      );
    },
    [navigate]
  );

  const rows = useMemo(
    () =>
      newRelicAlertConditionTemplates.map((alertConditionTemplate) => ({
        name: (
          <div className="text-sm font-semibold text-default">
            {alertConditionTemplate?.name}
          </div>
        ),
        revision: (
          <div className="text-sm text-subdued">
            {alertConditionTemplate?.version}
          </div>
        ),
        default: (
          <div className="text-sm text-subdued">
            {alertConditionTemplate?.default ? "Yes" : "No"}
          </div>
        ),
        lastEdit: <Timestamp isoTime={alertConditionTemplate?.lastUpdatedAt} />,
        editedBy: (
          <Tooltip
            content={`Edited by ${alertConditionTemplate?.lastUpdatedBy?.name}`}
          >
            <Avatar account={alertConditionTemplate?.lastUpdatedBy} />
          </Tooltip>
        ),
        editButton: (
          <button
            className="h-2 w-2 text-action"
            onClick={() => handleClickEdit(alertConditionTemplate.id)}
            aria-label={`Edit ${alertConditionTemplate?.name}`}
          >
            <PencilIcon className="h-2 w-2" />
          </button>
        ),
      })),
    [newRelicAlertConditionTemplates, handleClickEdit]
  );

  const isFilterActive =
    queryParams.search !== undefined ||
    queryParams.sort !== NewRelicConditionTemplateSortEnum.NameAsc;

  return (
    <div className="flex flex-col gap-3">
      <FilterBar
        isActive={isFilterActive}
        onResetFilters={handleResetFilters}
        className="p-1"
      >
        <Search
          placeholder="Search templates"
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
      </FilterBar>
      <Table
        emptyMessage="No alert condition templates found"
        defaultSort={queryParams.sort}
        onSortChange={handleSortChange}
        headers={ALERT_CONDITIONS_TABLE_HEADERS}
        loading={loading}
        rows={rows}
        {...pagination}
      />
    </div>
  );
};

const ALERT_CONDITIONS_TABLE_HEADERS: TableHeader[] = [
  { label: "Name", accessor: "name", width: "100%", sort: "NAME" },
  { label: "Revision", accessor: "revision" },
  { label: "Default", accessor: "default" },
  { label: "Last Edited", accessor: "lastEdit" },
  { label: "Edited By", accessor: "editedBy", align: "center" },
  { label: " ", accessor: "editButton" },
];

const alertConditionTemplatesQueryParams = {
  sort: withDefault(
    createEnumParam(Object.values(NewRelicConditionTemplateSortEnum)),
    NewRelicConditionTemplateSortEnum.NameAsc
  ),
  search: StringParam,
};
