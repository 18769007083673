import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  AwsAccountFilter,
  AwsAccountSortEnum,
  AwsAccountsQuery,
} from "@m/api/v4/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

export const GET_AWS_ACCOUNTS_QUERY = gql(/* GraphQL */ `
  query AwsAccounts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [AwsAccountSortEnum]
    $where: AwsAccountFilter
  ) {
    awsAccounts(
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          accountId
          added
          authMethod
          awsOrgAccountType
          company {
            id
            connectwisePk
            identifier
            name
            slug
          }
          disabled
          email
          externalId
          isSyncing
          lastCompletedSynced
          name
          oktaApp {
            embedUrl
          }
          payerAccountId: rootAwsAccountId
          payerType
          signinUrl
          supportPlan
          supportsCrossAccountAuth
          syncDisableReason
          syncsEnabled
        }
      }
    }
  }
`);

export interface AwsAccountsParams {
  search: string;
  sort: string;
  status: string;
  payersOnly: boolean;
}

export type AwsAccount =
  AwsAccountsQuery["awsAccounts"]["edges"][number]["node"];

export const useAwsAccounts = (queryParams: AwsAccountsParams) => {
  const where = getAwsAccountsWhereClause(queryParams);

  const { data, fetchMore, ...result } = useQuery(GET_AWS_ACCOUNTS_QUERY, {
    variables: {
      ...INITIAL_PAGINATION,
      sort: queryParams.sort as AwsAccountSortEnum,
      where,
    },
    onError: () => toast.error("Unable to load AWS accounts"),
    notifyOnNetworkStatusChange: true,
  });

  const { edges = [], pageInfo = {}, totalCount = 0 } = data?.awsAccounts ?? {};

  const awsAccounts = edges.map((edge) => edge.node) ?? [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return { data: { awsAccounts }, pagination, ...result };
};

export const getAwsAccountsWhereClause = (queryParams: AwsAccountsParams) => {
  const where: AwsAccountFilter = {
    and: [
      {
        disabled: queryParams.status === "disabled",
      },
    ],
  };

  if (queryParams.search) {
    where.and.push({
      or: [
        { company: { mcnIlike: `%${queryParams.search}%` } },
        { company: { nameIlike: `%${queryParams.search}%` } },
        { emailIlike: `%${queryParams.search}%` },
        { idLike: `%${queryParams.search}%` },
        { nameIlike: `%${queryParams.search}%` },
      ],
    });
  }

  if (queryParams.payersOnly) {
    // todo: replace this with filter by awsOrgAccountType
    where.and.push({
      payerTypeIn: [
        1, // PayerType.Dedicated
        2, // PayerType.Shared
      ],
    });
  }

  return where;
};
