import { Button } from "@m/ui";

import { PageTitle } from "@atlas/components";
import { PATHS } from "@atlas/constants";

import "../api";
import { NewRelicDashboardTemplatesTable } from "../components";

export const NewRelicDashboardTemplatesPage = () => {
  return (
    <main className="flex flex-col gap-3">
      <PageTitle
        title="Dashboards"
        description={
          <div className="text-sm font-normal text-subdued">
            Manage New Relic dashboard templates.
          </div>
        }
        actions={
          <Button
            kind="primary"
            size="small"
            to={PATHS.SETTINGS_DASHBOARD_TEMPLATE_CREATE}
          >
            Create
          </Button>
        }
      />
      <NewRelicDashboardTemplatesTable />
    </main>
  );
};
