import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";

export const GET_NEW_RELIC_DASHBOARD_TEMPLATE = gql(/* GraphQL */ `
  query GetNewRelicDashboardTemplate($id: ID!) {
    newRelicDashboardTemplate(id: $id) {
      id
      data
      name
      default
      apmRequirement
      version
    }
  }
`);

export const useNewRelicDashboardTemplate = (id: string) => {
  const { data, ...result } = useQuery(GET_NEW_RELIC_DASHBOARD_TEMPLATE, {
    variables: { id },
  });

  const dashboardTemplate = data?.newRelicDashboardTemplate ?? null;

  return { data: { dashboardTemplate }, ...result };
};
