import { useMutation } from "@apollo/client";

import { gql } from "@m/api/v4";

export const CREATE_NEW_RELIC_CONDITION = gql(/* GraphQL */ `
  mutation CreateNewRelicCondition($input: CreateNewRelicConditionInput!) {
    createNewRelicCondition(input: $input) {
      ok
      message
    }
  }
`);

export const useCreateNewRelicCondition = (accountId: string) => {
  const [mutate, result] = useMutation(CREATE_NEW_RELIC_CONDITION);

  const createNewRelicCondition = (templateId: string) => {
    return mutate({ variables: { input: { accountId, templateId } } });
  };

  return [createNewRelicCondition, result] as const;
};
