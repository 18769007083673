import { ChangeEvent, FormEvent, useState } from "react";

import {
  Banner,
  Button,
  ButtonRadioGroup,
  Buttons,
  DynamicSelect,
  Field,
  Fields,
  Input,
  Modal,
  OptionType,
  Suspensed,
  Textarea,
} from "@m/ui";
import { fromSnakeCaseToProperCase } from "@m/utils";

import { AwsAccount, useCompanies, useUpdateAwsAccount } from "../api";
import { AwsOrgAccountType, PayerType } from "../types";

interface EditAwsAccountModalProps {
  awsAccount: AwsAccount;
  onClose: () => void;
}

export const AwsAccountEditModal = ({
  awsAccount: {
    accountId,
    awsOrgAccountType,
    company,
    externalId,
    name,
    payerType,
  },
  onClose,
}: EditAwsAccountModalProps) => {
  const [selectedCustomer, setSelectedCustomer] = useState<OptionType>(
    company
      ? {
          value: company?.id,
          label: company?.name,
        }
      : null
  );
  const [confirmation, setConfirmation] = useState<string>("");

  const {
    data: { companies },
    loading: companiesLoading,
  } = useCompanies();

  const [updateAwsAccount, { loading: updateAwsAccountLoading }] =
    useUpdateAwsAccount();

  const handleSubmit = (e: FormEvent<AwsAccountEditModalForm>) => {
    e.preventDefault();

    const { customerSelect, externalIdTextarea, payerTypeRadioGroup } =
      e.currentTarget.elements;

    updateAwsAccount({
      id: accountId,
      companyId: customerSelect.value,
      externalId: externalIdTextarea.value,
      payerType: payerTypeRadioGroup?.value,
    }).then(onClose);
  };

  const handleCustomerChange = (selectedCustomer) => {
    setSelectedCustomer(selectedCustomer);
  };

  const handleConfirmationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmation(e.currentTarget.value);
  };

  const customerOptions = companies.map((company) => ({
    value: company.id,
    label: company.name,
  }));

  const payerTypeOptions = Object.values(PayerType).map((type) => ({
    label: fromSnakeCaseToProperCase(type),
    value: type,
  }));

  const impactedCustomers = [
    company?.name,
    selectedCustomer?.value !== company?.id && selectedCustomer?.name,
  ]
    .filter(Boolean)
    .join(" and ");

  const isConfirmationValid =
    confirmation === name || confirmation === accountId;

  return (
    <Modal
      header={<Modal.Title className="mb-2">Edit AWS Account</Modal.Title>}
      onClose={onClose}
      open
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <Fields>
          <Field label="Account Name" labelId="account-name" row>
            <div
              aria-labelledby="account-name"
              className="flex justify-end gap-1"
            >
              {name}
            </div>
          </Field>
          <Field label="Account ID" labelId="account-id" row>
            <div
              aria-labelledby="account-id"
              className="flex justify-end gap-1"
            >
              {accountId}
            </div>
          </Field>
          <Field
            label="Customer"
            htmlFor="customer"
            required={true}
            flag="required"
          >
            <Suspensed loading={companiesLoading} height="2.5rem">
              <DynamicSelect
                id="customer"
                name="customerSelect"
                aria-label="Select Customer"
                initialOptions={customerOptions}
                value={selectedCustomer}
                placeholder="Select Customer"
                onSelectedOptionsChange={handleCustomerChange}
                required
              />
            </Suspensed>
          </Field>
          <Field label="External ID" htmlFor="external-id">
            <Textarea
              defaultValue={externalId}
              id="external-id"
              name="externalIdTextarea"
              rows={5}
            />
          </Field>
          {awsOrgAccountType === AwsOrgAccountType.Payer && (
            <ButtonRadioGroup
              defaultValue={payerType}
              label="Payer Type"
              name="payerTypeRadioGroup"
              options={payerTypeOptions}
              width="fill-even"
            />
          )}
        </Fields>

        {(company || selectedCustomer) && (
          <Banner
            status="warning"
            label={
              <>
                Are you sure you want to apply these changes?
                <br />
                <strong>
                  This will impact all users of {impactedCustomers}.
                </strong>
              </>
            }
          />
        )}

        <Field
          label={
            <div className="text-sm font-medium text-default">
              To confirm changes, type the account name or ID below.
            </div>
          }
          htmlFor="confirmation"
        >
          <Input
            id="confirmation"
            onChange={handleConfirmationChange}
            placeholder={name ?? accountId}
            value={confirmation}
          />
        </Field>

        <Buttons align="right">
          <Button
            disabled={
              !isConfirmationValid || !selectedCustomer || companiesLoading
            }
            kind="primary"
            loading={updateAwsAccountLoading}
            type="submit"
          >
            Confirm
          </Button>
          <Button kind="secondary" fill="none" onClick={onClose}>
            Cancel
          </Button>
        </Buttons>
      </form>
    </Modal>
  );
};

interface AwsAccountEditModalFormElements extends HTMLFormControlsCollection {
  customerSelect: HTMLSelectElement;
  externalIdTextarea: HTMLTextAreaElement;
  payerTypeRadioGroup?: HTMLInputElement;
}

interface AwsAccountEditModalForm extends HTMLFormElement {
  readonly elements: AwsAccountEditModalFormElements;
}
