import { useQuery } from "@apollo/client";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { StatusCodePreference } from "@m/api/v4/types";
import {
  Button,
  Buttons,
  Checkbox,
  Confirm,
  DynamicSelect,
  Field,
  Input,
  Suspensed,
  useModalState,
} from "@m/ui/components";
import { formatDate, fromSnakeCaseToProperCase } from "@m/utils";

import { PageTitle } from "@atlas/components";

import {
  GET_NEW_RELIC_DOMAIN_MONITOR_DETAILS,
  useDeleteNewRelicDomainMonitor,
  useNewRelicDomainMonitorDetails,
  useUpdateNewRelicDomainMonitor,
} from "../api";

export const NewRelicDomainMonitorDetailsPage = () => {
  const { domainMonitorId } = useParams<{ domainMonitorId: string }>();
  const navigate = useNavigate();

  const {
    data: { domainMonitor },
    loading,
    error,
  } = useNewRelicDomainMonitorDetails(domainMonitorId);

  const [updateNewRelicDomainMonitor, { loading: updateLoading }] =
    useUpdateNewRelicDomainMonitor(domainMonitorId);

  const [deleteNewRelicDomainMonitor, { loading: deleteLoading }] =
    useDeleteNewRelicDomainMonitor(domainMonitorId);

  const deleteConfirmation = useModalState();

  const returnToDomainMonitors = useCallback(() => navigate("../"), [navigate]);

  const handleCancel = () => returnToDomainMonitors();

  const handleDeleteDomainMonitor = () => {
    deleteNewRelicDomainMonitor().then(returnToDomainMonitors);
  };

  const handleSaveDomainMonitor: React.FormEventHandler<
    NewRelicDomainMonitorForm
  > = (e) => {
    e.preventDefault();

    const {
      name,
      domain,
      expireThreshold,
      statusCodeAlertPreference,
      enabled,
    } = e.currentTarget.elements;

    updateNewRelicDomainMonitor({
      domain: domain.value,
      enabled: enabled.checked,
      expireThreshold: parseInt(expireThreshold.value),
      name: name.value,
      statusCodeAlertPreference:
        statusCodeAlertPreference.value as StatusCodePreference,
    });
  };

  useEffect(() => {
    if (error || domainMonitor === null) returnToDomainMonitors();
  }, [error, domainMonitor, returnToDomainMonitors]);

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSaveDomainMonitor}>
      <PageTitle title="Edit Domain Monitor" />

      <div className="grid max-w-xl grid-cols-2 gap-3">
        <Suspensed loading={loading}>
          <Field
            className="w-fit whitespace-nowrap"
            label="ID"
            labelId="id"
            row
          >
            <span
              aria-labelledby="id"
              className="text-sm font-semibold text-subdued"
            >
              {domainMonitor?.databaseId}
            </span>
          </Field>
        </Suspensed>
        <Suspensed loading={loading}>
          <Field
            className="w-fit whitespace-nowrap"
            label="Last Synced"
            labelId="last-synced"
            row
          >
            <span
              aria-labelledby="last-synced"
              className="text-sm font-semibold text-subdued"
            >
              {domainMonitor?.lastSynced
                ? formatDate(domainMonitor.lastSynced, { longFormat: true })
                : "Never"}
            </span>
          </Field>
        </Suspensed>
        <Suspensed loading={loading}>
          <Field
            className="w-fit whitespace-nowrap"
            label="Registrar Name"
            labelId="registrar-name"
            row
          >
            <span
              aria-labelledby="registrar-name"
              className="text-sm font-semibold text-subdued"
            >
              {domainMonitor?.registrarName}
            </span>
          </Field>
        </Suspensed>
        <Suspensed loading={loading}>
          <Field
            className="w-fit whitespace-nowrap"
            label="Registrar IANA ID"
            labelId="registrar-iana-id"
            row
          >
            <span
              aria-labelledby="registrar-id"
              className="text-sm font-semibold text-subdued"
            >
              {domainMonitor?.registrarId}
            </span>
          </Field>
        </Suspensed>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <Suspensed loading={loading}>
          <Field
            label="Name"
            htmlFor="name"
            description="Display name of the domain monitor."
          >
            <Input
              id="name"
              name="name"
              defaultValue={domainMonitor?.name}
              required
            />
          </Field>
        </Suspensed>
        <Suspensed loading={loading}>
          <Field
            label="Domain"
            htmlFor="domain"
            description="Specifies the domain name to check."
          >
            <Input
              id="domain"
              name="domain"
              defaultValue={domainMonitor?.domain}
              required
            />
          </Field>
        </Suspensed>
        <Suspensed loading={loading}>
          <Field
            label="Expire Threshold"
            htmlFor="expire-threshold"
            description="An incident is created this many days before the domain expires."
          >
            <Input
              id="expire-threshold"
              name="expireThreshold"
              defaultValue={domainMonitor?.expireThreshold}
              required
              type="number"
            />
          </Field>
        </Suspensed>
        <Suspensed loading={loading}>
          <Field
            label="Status Code Alert Preference"
            htmlFor="status-code-alert-preference"
            description="Filter out EPP status codes from creating incidents."
          >
            <DynamicSelect
              id="status-code-alert-preference"
              name="statusCodeAlertPreference"
              aria-label="Select status code alert preference"
              initialOptions={Object.values(StatusCodePreference).map(
                (value) => ({ value, label: fromSnakeCaseToProperCase(value) })
              )}
              required
            />
          </Field>
        </Suspensed>
        <Suspensed loading={loading}>
          <Checkbox
            defaultChecked={domainMonitor?.enabled}
            description="Whether or not the monitor is enabled."
            id="enabled"
            label="Enable monitor"
            name="enabled"
          />
        </Suspensed>
      </div>

      <Buttons>
        <Button
          kind="destructive"
          fill="subdued"
          onClick={deleteConfirmation.open}
          disabled={loading || updateLoading || deleteLoading}
        >
          Delete
        </Button>
        <span className="flex grow justify-end gap-2">
          <Button
            kind="primary"
            fill="none"
            className="px-4"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            kind="primary"
            className="px-4"
            type="submit"
            loading={updateLoading}
            disabled={loading || deleteLoading}
          >
            Save
          </Button>
        </span>
      </Buttons>

      <Confirm
        actions={
          <>
            <Button
              kind="primary"
              onClick={handleDeleteDomainMonitor}
              loading={deleteLoading}
            >
              Delete
            </Button>
            <Button
              kind="primary"
              fill="none"
              onClick={deleteConfirmation.close}
              disabled={deleteLoading}
            >
              Cancel
            </Button>
          </>
        }
        onClose={deleteConfirmation.close}
        open={deleteConfirmation.isOpen}
      >
        Are you sure you want to delete this domain monitor?
        <strong>This action cannot be undone.</strong>
      </Confirm>
    </form>
  );
};

NewRelicDomainMonitorDetailsPage.Crumb = function Crumb() {
  const { domainMonitorId } = useParams<{ domainMonitorId: string }>();

  const { data, loading } = useQuery(GET_NEW_RELIC_DOMAIN_MONITOR_DETAILS, {
    variables: {
      id: domainMonitorId,
    },
    fetchPolicy: "cache-first",
  });

  const domain = data?.newRelicDomainMonitor?.domain ?? "Domain";

  return (
    <Suspensed loading={loading} width="200px" height="20px">
      {domain}
    </Suspensed>
  );
};

export interface NewRelicDomainMonitorFormElements
  extends HTMLFormControlsCollection {
  name: HTMLInputElement;
  domain: HTMLInputElement;
  expireThreshold: HTMLInputElement;
  statusCodeAlertPreference: HTMLSelectElement;
  enabled: HTMLInputElement;
}

export interface NewRelicDomainMonitorForm extends HTMLFormElement {
  readonly elements: NewRelicDomainMonitorFormElements;
}
