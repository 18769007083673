import { useEntitlements, useExtensions } from "@m/api";
import { useAuth } from "@m/login";

import { checkForAll, checkForAny } from "./utils";

type MatchOptions = "all" | "any";

export interface AccessRequirements {
  // Entitlement(s) required to grant access
  entitlement?: string | string[];

  // Feature flag(s) required to grant access
  feature?: string | string[];

  // Mutation restriction(s) to block access
  mutation?: string | string[];

  // Query restriction(s) to block access
  query?: string | string[];

  // Role(s) required to grant access
  role?: string | string[];

  // Whether any or all entitlements are required to grant access
  matchEntitlements?: MatchOptions;

  // Whether any or all feature flags are required to grant access
  matchFeatures?: MatchOptions;

  // Whether any or all mutation restrictions should block access
  matchMutations?: MatchOptions;

  // Whether any or all query restrictions should block access
  matchQueries?: MatchOptions;

  // Whether any or all roles are required to grant access
  matchRoles?: MatchOptions;
}

export const useAccessRequired = ({
  entitlement,
  feature,
  mutation,
  query,
  role,
  matchEntitlements = "all",
  matchFeatures = "all",
  matchMutations = "any",
  matchQueries = "any",
  matchRoles = "all",
}: AccessRequirements): boolean => {
  const { user } = useAuth();
  const entitlements = useEntitlements();
  const { extensions } = useExtensions();

  //if we fail to load required data for any reason, block access
  if (!user) return false;
  if (!extensions) return false;

  const enabledFeatureFlags = extensions?.featureFlags || [];
  const restrictedQueries = extensions.rbac?.queries?.restricted || [];
  const restrictedMutations = extensions.rbac?.mutations?.restricted || [];
  const userRoles = user.roles || [];

  // if the user does not have the required entitlements, block access
  if (!foundMatch(entitlement, entitlements, matchEntitlements)) return false;

  //if the user does not have the required feature flags, block access
  if (
    !foundMatch(feature, enabledFeatureFlags, matchFeatures) &&
    !process.env.REACT_APP_IGNORE_FEATURE_FLAGS
  )
    return false;

  // if the user has restricted access to the included queries, block access
  if (foundMatch(query, restrictedQueries, matchQueries)) return false;

  // if the user has restricted access to the included mutations, block access
  if (foundMatch(mutation, restrictedMutations, matchMutations)) return false;

  // if the user does not have the required roles, block access
  if (!foundMatch(role, userRoles, matchRoles)) return false;

  // else allow access
  return true;
};

const foundMatch = (
  option: string | string[],
  list: string[],
  match: MatchOptions
) => {
  if (match === "all") return checkForAll(option, list);
  if (match === "any") return checkForAny(option, list);
};
